import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSms, faTasks,faListCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const lookups = [
  {
    title: 'Email Template',
    description: 'Manage and create email templates for your communications.',
    icon: faEnvelope,
    route: '/app/lookups/email-template',
  },
  {
    title: 'SMS Template',
    description: 'Manage and create SMS templates for your notifications.',
    icon: faSms,
    route: '/app/lookups/sms-template',
  },
  {
    title: 'Task Status',
    description: 'Manage the different statuses for tasks in your system.',
    icon: faTasks,
    route: '/app/lookups/task-status',
  },
  {
    title: 'Task Progress Stage',
    description: 'Manage the stages of task progress to track progress effectively.',
    icon: faListCheck, // You can change the icon if needed.
    route: '/app/lookups/task-progress-stage',
  },
];

const lookups_Feedback = [
  {
    title: 'Feedback Category',
    description: 'Manage the different categories for feedback in your system.',
    icon: faTasks,
    route: '/app/lookups/feedback-category',
  },
  {
    title: 'Feedback Type',
    description: 'Manage the different types for feedback in your system.',
    icon: faTasks,
    route: '/app/lookups/feedback-type',
  },
];

const Lookups_Meetings = [
  {
    title: 'Venue Management',
    description: 'Manage the different venues for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/venue-management',
  },
  {
    title: 'Location Management',
    description: 'Manage the different locations for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/location-management',
  },
  {
    title: 'Meeting Type',
    description: 'Manage the different types for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-type',
  },
  {
    title: 'Meeting Status',
    description: 'Manage the different statuses for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-status',
  },
];

const Lookups = () => {
  const navigate = useNavigate();

  const handleView = (route) => {
    navigate(route);
  };

  return (
    <div className="container mt-4">
      <h2>Manage Lookups</h2>
      <Row>
        {lookups.map((lookup, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="shadow h-100" onClick={() => handleView(lookup.route)} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex flex-column align-items-center text-center">
                <FontAwesomeIcon icon={lookup.icon} size="3x" className="mb-3" />
                <Card.Title>{lookup.title}</Card.Title>
                <Card.Text>{lookup.description}</Card.Text>
                <Button variant="primary" onClick={() => handleView(lookup.route)}>View</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <br />
      <h2>Feedback Lookups</h2>
      <Row>
        {lookups_Feedback.map((lookup, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="shadow h-100" onClick={() => handleView(lookup.route)} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex flex-column align-items-center text-center">
                <FontAwesomeIcon icon={lookup.icon} size="3x" className="mb-3" />
                <Card.Title>{lookup.title}</Card.Title>
                <Card.Text>{lookup.description}</Card.Text>
                <Button variant="primary" onClick={() => handleView(lookup.route)}>View</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <br />
      <h2>Meetings Lookups</h2>
      <Row>
        {Lookups_Meetings.map((lookup, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="shadow h-100" onClick={() => handleView(lookup.route)} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex flex-column align-items-center text-center">
                <FontAwesomeIcon icon={lookup.icon} size="3x" className="mb-3" />
                <Card.Title>{lookup.title}</Card.Title>
                <Card.Text>{lookup.description}</Card.Text>
                <Button variant="primary" onClick={() => handleView(lookup.route)}>View</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Lookups;
