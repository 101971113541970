import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

const invalidateCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
};

const LOCATION_VENUE_LIST_CACHE = 'locationVenueListCache';

export const getLocationVenueList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(LOCATION_VENUE_LIST_CACHE)) {
        return getCache(LOCATION_VENUE_LIST_CACHE);
    }

    try {
        const response = await api_locations.get('/api/LocationVenue/List');
        setCache(LOCATION_VENUE_LIST_CACHE, response.data.results);
        return response.data.results;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getLocationVenueById = async (id, forceUpdate = false) => {
    const cacheKey = `${LOCATION_VENUE_LIST_CACHE}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_locations.get(`/api/LocationVenue/Get/${id}`);
        setCache(cacheKey, response.data.results);
        return response.data.results;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const insertLocationVenue = async (venueData) => {
    try {
        const response = await api_locations.post('/api/LocationVenue/Save', venueData);
        invalidateCache(LOCATION_VENUE_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateLocationVenue = async (venueData) => {
    try {
        const response = await api_locations.post('/api/LocationVenue/Update', venueData);
        invalidateCache(LOCATION_VENUE_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const deleteLocationVenue = async (id) => {
    try {
        const response = await api_locations.post('/api/LocationVenue/Delete', { id });
        invalidateCache(LOCATION_VENUE_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPaginatedLocationVenues = async (page, pageSize, forceUpdate = false) => {
    const cacheKey = `${LOCATION_VENUE_LIST_CACHE}_page_${page}_${pageSize}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_locations.get(`/api/LocationVenue/PaginatedList?page=${page}&pageSize=${pageSize}`);
        setCache(cacheKey, response.data.results);
        return response.data.results;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getCustomLocationVenueList = async (query, forceUpdate = false) => {
    const cacheKey = `${LOCATION_VENUE_LIST_CACHE}_custom_${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_locations.post('/api/LocationVenue/CustomList', { query });
        setCache(cacheKey, response.data.results);
        return response.data.results;
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getScalarValue = async (query, forceUpdate = false) => {
    const cacheKey = `scalarValue_${query}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_locations.post('/api/LocationVenue/GetValue', { query });
        setCache(cacheKey, response.data.results);
        return response.data.results;
    } catch (error) {
        return Promise.reject(error);
    }
};
