import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, ListGroup } from 'react-bootstrap';

const StepOne = ({ meetingTypes, meetingDetails, handleMeetingDetailChange, handleNextStep }) => {
    const stepRef = useRef(null);
    const [selectedType, setSelectedType] = useState(meetingDetails.TypeID || "");

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleTypeSelect = (typeId) => {
        setSelectedType(typeId);
        handleMeetingDetailChange("typeID", typeId);
    };

    const handleProceed = () => {
        if (selectedType) handleNextStep();
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="mb-4 text-center">Choose Meeting Type</h4>
                    <ListGroup className="mb-4">
                            {meetingTypes.map((type) => (
                                <ListGroup.Item
                                    key={type.typeID}
                                    action
                                    active={selectedType === type.typeID}
                                    onClick={() => handleTypeSelect(type.typeID)}
                                >
                                    {type.name}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    <div className="text-center">
                        <Button
                            variant="primary"
                            onClick={handleProceed}
                            disabled={!selectedType}
                        >
                            Proceed <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepOne;
