import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

// Cache Helper Functions
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

const invalidateCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
};

// API Functions with Caching

// LocationSocialMedia List
const LOCATION_SOCIAL_MEDIA_LIST_CACHE = 'locationSocialMediaListCache';
export const LocationSocialMediaList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(LOCATION_SOCIAL_MEDIA_LIST_CACHE)) {
        return getCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/LocationSocialMedia/List');
        setCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// LocationSocialMedia By ID
export const LocationSocialMediaById = async (id, forceUpdate = false) => {
    const cacheKey = `locationSocialMediaByIdCache_${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/LocationSocialMedia/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Insert LocationSocialMedia
export const InsertLocationSocialMedia = async (locationSocialMedia) => {
    try {
        const response = await api_locations.post('/api/LocationSocialMedia/Insert', locationSocialMedia);
        invalidateCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update LocationSocialMedia
export const UpdateLocationSocialMedia = async (locationSocialMedia) => {
    try {
        const response = await api_locations.post('/api/LocationSocialMedia/Update', locationSocialMedia);
        invalidateCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get All LocationSocialMedia
export const LocationSocialMedia = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(LOCATION_SOCIAL_MEDIA_LIST_CACHE)) {
        return getCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/LocationSocialMedia');
        setCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// LocationSocialMedia By Location ID
export const LocationSocialMediaByLocationId = async (locationId, forceUpdate = false) => {
    const cacheKey = `locationSocialMediaByLocationCache_${locationId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/LocationSocialMedia/ByLocation/${locationId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update LocationSocialMedia Handle
export const UpdateLocationSocialMediaHandle = async (mediaId, handle) => {
    try {
        const response = await api_locations.post(`/api/LocationSocialMedia/UpdateHandle/${mediaId}`, handle);
        invalidateCache(LOCATION_SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
