import React, { useState } from "react";
import { Button, Card, Modal, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus, faClose, faSave } from "@fortawesome/free-solid-svg-icons";

const SetupsTab = ({ setups, addSetup, updateSetup, removeSetup }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedSetup, setSelectedSetup] = useState(null);
    const [newSetup, setNewSetup] = useState({
        setupID: 0,
        meetingID: 0,
        details: "",
    });

    // Columns for DataTable
    const columns = [
        {
            name: "Setup Name",
            selector: (row) => row.setupName,
            sortable: true,
        },
        {
            name: "Department",
            selector: (row) => row.departmentName,
            sortable: true,
        },
        {
            name: "Details",
            selector: (row) => row.details,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div className="d-flex gap-2">
                    <Button
                        variant="outline-primary"
                        size="xs"
                        onClick={() => handleEdit(row)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button
                        variant="outline-danger"
                        size="xs"
                        onClick={() => handleRemove(row.setupID)}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </Button>
                </div>
            ),
            width: "120px",
        },
    ];

    // Add Setup Modal Handlers
    const handleAddSetup = () => {
        addSetup(newSetup);
        setNewSetup({ setupID: 0, meetingID: 0, details: "" });
        setShowAddModal(false);
    };

    // Edit Setup Modal Handlers
    const handleEdit = (setup) => {
        setSelectedSetup(setup);
        setShowEditModal(true);
    };

    const handleUpdateSetup = () => {
        updateSetup(selectedSetup);
        setSelectedSetup(null);
        setShowEditModal(false);
    };

    // Remove Setup Handler
    const handleRemove = (setupID) => {
        removeSetup(setupID);
    };

    return (
        <Card className="shadow-sm p-3">
            <div className="d-flex justify-content-between mb-3">
                <h4 className="text-primary">Setups</h4>
                <Button variant="primary" size="sm" onClick={() => setShowAddModal(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Add Setup
                </Button>
            </div>
            <DataTable
                columns={columns}
                data={setups}
                pagination
                highlightOnHover
            />

            {/* Add Setup Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Setup ID</Form.Label>
                            <Form.Control
                                type="number"
                                value={newSetup.setupID}
                                onChange={(e) =>
                                    setNewSetup({ ...newSetup, setupID: e.target.value })
                                }
                                placeholder="Enter Setup ID"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Meeting ID</Form.Label>
                            <Form.Control
                                type="number"
                                value={newSetup.meetingID}
                                onChange={(e) =>
                                    setNewSetup({ ...newSetup, meetingID: e.target.value })
                                }
                                placeholder="Enter Meeting ID"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Details</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={newSetup.details}
                                onChange={(e) =>
                                    setNewSetup({ ...newSetup, details: e.target.value })
                                }
                                placeholder="Enter Details"
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShowAddModal(false)}>
                        <FontAwesomeIcon icon={faClose} /> Cancel
                    </Button>
                    <Button variant="primary" size="sm" onClick={handleAddSetup}>
                        <FontAwesomeIcon icon={faSave} /> Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Setup Modal */}
            {selectedSetup && (
                <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Setup</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Details</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={selectedSetup.details}
                                    onChange={(e) =>
                                        setSelectedSetup({
                                            ...selectedSetup,
                                            details: e.target.value,
                                        })
                                    }
                                    placeholder="Enter Details"
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" size="sm" onClick={() => setShowEditModal(false)}>
                            <FontAwesomeIcon icon={faClose} /> Cancel
                        </Button>
                        <Button variant="primary" size="sm" onClick={handleUpdateSetup}>
                            <FontAwesomeIcon icon={faSave} /> Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Card>
    );
};

export default SetupsTab;
