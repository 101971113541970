import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Nav, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodePullRequest, faComment, faFile, faGears, faMapLocation, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Parts
import MeetingDetails from './meetingDetails';
import VenueTab from './VenueTab';
import AttendeesTab from './AttendeesTab';
import SetupsTab from './SetupsTab';
import RequestsTab from './RequestsTab';
import CommentsTab from './CommentsTab';
import FilesTab from './FilesTab';

// API Service
import { updateMeetingVenue, saveMeetingAttendee, deleteMeetingAttendee } from '../../../../../services/ccms/meetingsManagement';

const MySwal = withReactContent(Swal);

const ViewMeeting = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [meetingData, setMeetingData] = useState({});

    // Fetch location data on mount
    useEffect(() => {
        const fetchLocationData = async () => {
            try {
                const data = location.state;
                if (!data) {
                    navigate("/app/meetings");
                } else {
                    setMeetingData(data);
                    setIsLoaded(true);
                }
            } catch (error) {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch location data.",
                });
            }
        };

        fetchLocationData();
    }, [location.state, navigate]);

    // Function to update meeting venue
    const handleUpdateMeetingVenue = async (venueData) => {
        try {
            const response = await updateMeetingVenue(venueData);

            if (response.status === "Success") {
                MySwal.fire({
                    icon: "success",
                    title: "Success",
                    text: response.message,
                });
            } else {
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to update venue details.",
                });
            }
        } catch (error) {
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while updating venue details.",
            });
        }
    };

    const handleAddAttendee = async (attendee) => {
        try {
            // Update the API with the new attendee details
            const response = await saveMeetingAttendee(attendee);
    
            if (response.status === "Success") {
                // Update the local state to include the new attendee
                setMeetingData((prevData) => ({
                    ...prevData,
                    attendees: [...(prevData.attendees || []), attendee],
                }));
    
                // Show success notification
                MySwal.fire({
                    icon: "success",
                    title: "Attendee Added",
                    text: response.message,
                });
            } else {
                // Show error notification
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to add attendee.",
                });
            }
        } catch (error) {
            // Handle unexpected errors
            console.error("Failed to add attendee:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while adding the attendee.",
            });
        }
    };

    const handleAddSetup = (setup) => {
        setMeetingData((prevData) => ({
            ...prevData,
            setups: [...(prevData.setups || []), setup],
        }));
    };
    
    const handleUpdateSetup = (updatedSetup) => {
        setMeetingData((prevData) => ({
            ...prevData,
            setups: prevData.setups.map((setup) =>
                setup.setupID === updatedSetup.setupID ? updatedSetup : setup
            ),
        }));
    };
    
    const handleRemoveSetup = (setupID) => {
        setMeetingData((prevData) => ({
            ...prevData,
            setups: prevData.setups.filter((setup) => setup.setupID !== setupID),
        }));
    };

    const handleAddRequest = (request) => {
        setMeetingData((prevData) => ({
            ...prevData,
            requests: [...(prevData.requests || []), request],
        }));
    };
    
    const handleUpdateRequest = (updatedRequest) => {
        setMeetingData((prevData) => ({
            ...prevData,
            requests: prevData.requests.map((request) =>
                request.requestID === updatedRequest.requestID ? updatedRequest : request
            ),
        }));
    };
    
    const handleRemoveRequest = (requestID) => {
        setMeetingData((prevData) => ({
            ...prevData,
            requests: prevData.requests.filter((request) => request.requestID !== requestID),
        }));
    };
    
    const handleRemoveAttendee = async (attendeeID) => {
        try {
            // Update the API with the new attendee details
            const response = await deleteMeetingAttendee(attendeeID);
    
            if (response.status === "Success") {
                // Update the local state to remove the attendee
                setMeetingData((prevData) => ({
                    ...prevData,
                    attendees: (prevData.attendees || []).filter((attendee) => attendee.id !== attendeeID),
                }));
    
                // Show success notification
                MySwal.fire({
                    icon: "success",
                    title: "Attendee Removed",
                    text: response.message,
                });
            } else {
                // Show error notification
                MySwal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.message || "Failed to remove attendee.",
                });
            }
        } catch (error) {
            // Handle unexpected errors
            console.error("Failed to remove attendee:", error);
            MySwal.fire({
                icon: "error",
                title: "Error",
                text: "An unexpected error occurred while removing the attendee.",
            });
        }
    };

    return (
        <Fragment>
            <MeetingDetails meetingData={meetingData} isLoading={!isLoaded} />
            <Card className="shadow-sm mb-3 p-3" style={{ minHeight: "30em" }}>
                <div className="profile-tab">
                    <Tab.Container defaultActiveKey="venue">
                        <Nav as="ul" variant="pills" className="nav nav-tabs">
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="venue" className="nav-link">
                                    <FontAwesomeIcon icon={faMapLocation} /> Venue
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="attendees" className="nav-link">
                                    <FontAwesomeIcon icon={faUserGroup} /> Attendees
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="setups" className="nav-link">
                                    <FontAwesomeIcon icon={faGears} /> Setups
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="requests" className="nav-link">
                                    <FontAwesomeIcon icon={faCodePullRequest} /> Requests
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="comments" className="nav-link">
                                    <FontAwesomeIcon icon={faComment} /> Comments
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li" className="nav-item">
                                <Nav.Link eventKey="files" className="nav-link">
                                    <FontAwesomeIcon icon={faFile} /> Files
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-3">
                            <Tab.Pane eventKey="venue">
                                <VenueTab
                                    meetingData={meetingData.meetingVenueData}
                                    isLoading={!isLoaded}
                                    updateMeetingVenue={handleUpdateMeetingVenue}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="attendees">
                                <AttendeesTab 
                                    attendees={meetingData.meetingAttendees || []} 
                                    addAttendee={handleAddAttendee} 
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="setups">
                                <SetupsTab
                                    setups={meetingData.meetingSetups || []}
                                    addSetup={handleAddSetup}
                                    updateSetup={handleUpdateSetup}
                                    removeSetup={handleRemoveSetup}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="requests">
                                <RequestsTab
                                    requests={meetingData.meetingRequests || []}
                                    addRequest={handleAddRequest}
                                    updateRequest={handleUpdateRequest}
                                    removeRequest={handleRemoveRequest}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="comments">
                                <CommentsTab meetingID={meetingData.meetingID} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="files">
                                <FilesTab meetingID={meetingData.meetingID} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Card>
        </Fragment>
    );
};

export default ViewMeeting;
