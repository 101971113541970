import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import LocationModal from "./location/LocationModal"; // Import the modal component

// Page Title
import PageTitle from "../../../../layouts/PageTitle";

// API Service
import { LocationListView } from "../../../../../services/ccms/locationManagement/location/location_endpoints";

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async (forceUpdate = false) => {
    setIsLoaded(false);
    try {
      const response = await LocationListView(forceUpdate);
      if (response.status === "Success") {
        setLocations(response.results);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    } finally {
      setIsLoaded(true);
    }
  };

  const handleViewLocation = (location) => {
    navigate("/app/meetings/venue/location", { state: location });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
      width: "200px",
    },
    {
      name: "Description",
      selector: (row) => row.description || "N/A",
      sortable: true,
      wrap: true,
    },
    {
      name: "Color",
      selector: (row) => (
        <span
          className="badge"
          style={{
            backgroundColor: row.color,
            color: "#fff",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
        >
          {row.color}
        </span>
      ),
      width: "120px",
    },
    {
      name: "Venues",
      selector: (row) => row.venues.length || 0,
      sortable: true,
      width: "160px",
    },
    {
      name: "",
      cell: (row) => (
        <Button
          variant="outline-primary"
          size="xs"
          onClick={() => handleViewLocation(row)}
        >
          <i className="fas fa-eye"></i> View
        </Button>
      ),
      ignoreRowClick: true,
      width: "120px",
    },
  ];

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="d-flex align-items-center">
                
              </div>
              <Button size="xs" onClick={() => fetchLocations(true)}>
                <FontAwesomeIcon icon={faSyncAlt} />
              </Button>
            </div>
      <PageTitle
        motherMenu="Locations"
        motherMenuPath="/app/meetings/locations"
        activeMenu="List"
        button={
          <Button
            variant="primary"
            size="sm"
            onClick={() => setShowModal(true)} // Show modal on click
          >
            <i className="fa fa-plus-circle"></i> New Location
          </Button>
        }
      />
      <Card className="shadow">
        <Card.Body>
          <div className="table-responsive">
            {isLoaded ? (
              <DataTable
                columns={columns}
                data={locations}
                pagination
                highlightOnHover
                striped
              />
            ) : (
              <Shimmer type="table" width="100%" />
            )}
          </div>
        </Card.Body>
      </Card>

      {/* Modal for Adding Location */}
      <LocationModal
        show={showModal}
        handleClose={() => setShowModal(false)} // Close modal
        fetchLocations={fetchLocations} // Refresh locations after saving
      />
    </Fragment>
  );
};

export default Locations;
