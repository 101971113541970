import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Button, Tab, Nav } from "react-bootstrap";
import Shimmer from "../../../../../components/Custom/Loading/Shimmer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Context
import { UserRightsContext } from "../../../../../../context/UserRightsContext";

// API
import { UpdateLocation, LocationListView } from "../../../../../../services/ccms/locationManagement/location/location_endpoints";
import { SaveAddress } from "../../../../../../services/ccms/locationManagement/address/address_endpoint";
import { InsertLocationSocialMedia, UpdateLocationSocialMedia } from "../../../../../../services/ccms/locationManagement/location_socialmedia/location_socialmedia_endpoints";
import { InsertServiceTime, UpdateServiceTime } from "../../../../../../services/ccms/locationManagement/service_time/service_time_endpoints";
import { insertLocationVenue, updateLocationVenue } from "../../../../../../services/ccms/locationManagement/locationVanue";

// Tabs Components
import LocationDetails from "./tabs/LocationDetails";
import LocationVenues from "./tabs/LocationVenues";
import LocationAddress from "./tabs/LocationAddress";
import LocationSocialMedia from "./tabs/LocationSocialMedia";
import LocationPastors from "./tabs/LocationPastors";
import LocationServiceTimes from "./tabs/LocationServiceTimes";

const MySwal = withReactContent(Swal);

const ViewLocation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRights = useContext(UserRightsContext);

  const [locationDetails, setLocationDetails] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // Loading Indicators
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [isSocialMediaLoading, setIsSocialMediaLoading] = useState(false);
  const [isPastorsLoading, setIsPastorsLoading] = useState(false);
  const [isServiceTimesLoading, setIsServiceTimesLoading] = useState(false);
  const [isVenuesLoading, setIsVenuesLoading] = useState(false);

  // Fetch location data on mount
  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationData = location.state;
        if (!locationData) {
          navigate("/app/meetings/location");
        } else {
          setLocationDetails(locationData);
          setIsLoaded(true);
        }
      } catch (error) {
        MySwal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to fetch location data.",
        });
      }
    };

    fetchLocationData();
  }, [location.state, navigate]);

  if (!isLoaded) {
    return <Shimmer type="article" />;
  }

  const updatelocationDetails = async () => {
    // fetch updated location details from await LocationListView(true);
    try {
      const response = await LocationListView(true);
      if (response.status === "Success") {
        var locationData = response.results.find((loc) => loc.locationID === locationDetails.locationID);
        setLocationDetails(locationData);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };


  // SweetAlert functions
  const showSuccess = (title, message) => {
    MySwal.fire({
      icon: "success",
      title: title,
      text: message,
      timer: 1500,
      showConfirmButton: false,
    });
  };

  const showError = (title, message) => {
    MySwal.fire({
      icon: "error",
      title: title,
      text: message,
    });
  };

  const confirmAction = async (message, callback) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, proceed",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      return callback();
    }
    return null;
  };

  // API integration with SweetAlert
  const updateLocationInfo = async (updatedInfo) => {
    await confirmAction("Are you sure you want to update this location?", async () => {
      setIsUpdating(true);
      try {
        const response = await UpdateLocation(updatedInfo);
        if(response.status === "Success") {
        showSuccess("Update Location", response.message);
        } else {
          showError("Error updating location", response.message);
        }
      } catch (error) {
        showError("Error updating location", error.message);
      } finally {
        setIsUpdating(false);
      }
    });
  };

  const updateAddress = async (updatedAddress) => {
    await confirmAction("Are you sure you want to update this address?", async () => {
      setIsUpdating(true);
      try {
        const response = await SaveAddress(updatedAddress);
        if(response.status === "Success") {
          showSuccess("Address Saved", response.message);
        } else {
          showError("Error updating address", response.message);
        }
      } catch (error) {
        showError("Error updating address", error.message);
      } finally {
        setIsUpdating(false);
      }
    });
  };

  const insertSocialMedia = async (newSocialMedia) => {
    await confirmAction("Are you sure you want to add this social media?", async () => {
      setIsUpdating(true);
      setIsSocialMediaLoading(true);
      try {
        const response = await InsertLocationSocialMedia(newSocialMedia);
        if(response.status === "Success") {
          showSuccess("Added", response.message);
          await updatelocationDetails();
        } else {
          showError("Error!", response.message);
        }
      } catch (error) {
        showError("Error", error.message);
      } finally {
        setIsUpdating(false);
        setIsSocialMediaLoading(false);
      }
    });
  };

  const updateSocialMedia = async (updatedSocialMedia) => {
    await confirmAction("Are you sure you want to update this social media?", async () => {
      setIsUpdating(true);
      setIsSocialMediaLoading(true);
      try {
        const response = await UpdateLocationSocialMedia(updatedSocialMedia);
        if(response.status === "Success") {
          showSuccess("Updated", response.message);
          await updatelocationDetails();
        } else {
          showError("Error!", response.message);
        }
      } catch (error) {
        showError("Error!", error.message);
      } finally {
        setIsUpdating(false);
        setIsSocialMediaLoading(false);
      }
    });
  };

  const insertServiceTime = async (newServiceTime) => {
    await confirmAction("Are you sure you want to add this service time?", async () => {
      setIsUpdating(true);
      try {
        const response = await InsertServiceTime(newServiceTime);
        if(response.status === "Success") {
          showSuccess("Added", response.message);
          await updatelocationDetails();
        } else {
          showError("Error!", response.message);
        }
      } catch (error) {
        showError("Error adding service time", error.message);
      } finally {
        setIsUpdating(false);
      }
    });
  };

  const updateServiceTime = async (updatedServiceTime) => {
    await confirmAction("Are you sure you want to update this service time?", async () => {
      setIsUpdating(true);
      try {
        const response = await UpdateServiceTime(updatedServiceTime);
        if(response.status === "Success") {
          showSuccess("Updated", response.message);
          await updatelocationDetails();
        } else {
          showError("Error!", response.message);
        }
      } catch (error) {
        showError("Error updating service time", error.message);
      } finally {
        setIsUpdating(false);
      }
    });
  };

  const insertVenue = async (newVenue) => {
    await confirmAction("Are you sure you want to add this venue?", async () => {
      setIsUpdating(true);
      setIsVenuesLoading(true);
      try {
        await insertLocationVenue(newVenue);
        showSuccess("Venue added successfully!");
        await updatelocationDetails();
      } catch (error) {
        showError("Error adding venue", error.message);
      } finally {
        setIsUpdating(false);
        setIsVenuesLoading(false);
      }
    });
  };

  const updateVenue = async (updatedVenue) => {
    await confirmAction("Are you sure you want to update this venue?", async () => {
      setIsUpdating(true);
      setIsVenuesLoading(true);
      try {
        await updateLocationVenue(updatedVenue);
        showSuccess("Venue updated successfully!");
        await updatelocationDetails();
      } catch (error) {
        showError("Error updating venue", error.message);
      } finally {
        setIsUpdating(false);
        setIsVenuesLoading(false);
      }
    });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex align-items-center">
          <Button size="xs" variant="outline-primary" onClick={() => navigate("/app/meetings/locations")}>
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back
          </Button>
          <h1 className="ms-3 mb-0">{locationDetails.name}</h1>
        </div>
        <Button size="xs" onClick={() => updatelocationDetails()}>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Button>
      </div>

      <div className="profile-tab">
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey="details">
            <Nav as="ul" className="nav nav-tabs">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="details" className="nav-link">
                  Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="venues" className="nav-link">
                  Venues
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="address" className="nav-link">
                  Address
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="socialMedia" className="nav-link">
                  Social Media
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="pastors" className="nav-link">
                  Pastors
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item as="li" className="nav-item">
                <Nav.Link eventKey="serviceTimes" className="nav-link">
                  Service Times
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="pt-3">
              <Tab.Pane eventKey="details">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationDetails
                      locationInfo={locationDetails}
                      isLoading={isLocationLoading}
                      isUpdating={isUpdating}
                      updateLocationInfo={updateLocationInfo}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="venues">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationVenues
                      venues={locationDetails.venues}
                      isLoading={isVenuesLoading}
                      isSaving={isUpdating}
                      onInsert={insertVenue}
                      onUpdate={updateVenue}
                      locationID={locationDetails.locationID}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="address">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationAddress
                      address={locationDetails.address}
                      isLoading={isAddressLoading}
                      isUpdating={false}
                      updateAddress={updateAddress}
                      locationID={locationDetails.locationID}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="socialMedia">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationSocialMedia
                      socialMediaHandles={locationDetails.socialMedias}
                      isLoading={isSocialMediaLoading}
                      isSaving={isUpdating}
                      onInsert={insertSocialMedia}
                      onUpdate={updateSocialMedia}
                      locationID={locationDetails.locationID}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
              {/* <Tab.Pane eventKey="pastors">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationPastors pastors={locationDetails.locationID} />
                  </Card.Body>
                </Card>
              </Tab.Pane> */}
              <Tab.Pane eventKey="serviceTimes">
                <Card className="shadow mb-4">
                  <Card.Body>
                    <LocationServiceTimes
                      serviceTimes={locationDetails.serviceTimes}
                      isLoading={isServiceTimesLoading}
                      isSaving={isUpdating}
                      onInsert={insertServiceTime}
                      onUpdate={updateServiceTime}
                      locationID={locationDetails.locationID}
                    />
                  </Card.Body>
                </Card>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};

export default ViewLocation;
