import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';


const NewLocation = () => {

    return (
        <div>
            <h1>New Location</h1>
        </div>
    );
}

export default NewLocation;