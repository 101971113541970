import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

// Cache Helper Functions
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

const invalidateCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
};

// API Functions with Caching

// SocialMedia List
const SOCIAL_MEDIA_LIST_CACHE = 'socialMediaListCache';
export const SocialMediaList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(SOCIAL_MEDIA_LIST_CACHE)) {
        return getCache(SOCIAL_MEDIA_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/SocialMedia/List');
        setCache(SOCIAL_MEDIA_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// SocialMedia By ID
export const SocialMediaById = async (id, forceUpdate = false) => {
    const cacheKey = `socialMediaByIdCache_${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/SocialMedia/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Insert SocialMedia
export const SocialMediaInsert = async (data) => {
    try {
        const response = await api_locations.post('/api/SocialMedia/Insert', data);
        invalidateCache(SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update SocialMedia
export const SocialMediaUpdate = async (data) => {
    try {
        const response = await api_locations.post('/api/SocialMedia/Update', data);
        invalidateCache(SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// SocialMedia By Name
export const SocialMediaByName = async (name, forceUpdate = false) => {
    const cacheKey = `socialMediaByNameCache_${name}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/SocialMedia/ByName/${name}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update SocialMedia Link
export const SocialMediaUpdateLink = async (mediaId, link) => {
    try {
        const response = await api_locations.post(`/api/SocialMedia/UpdateLink/${mediaId}`, link);
        invalidateCache(SOCIAL_MEDIA_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get All SocialMedia
export const SocialMedia = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(SOCIAL_MEDIA_LIST_CACHE)) {
        return getCache(SOCIAL_MEDIA_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/SocialMedia');
        setCache(SOCIAL_MEDIA_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
