import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faPencil, faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Shimmer from "../../../../../../components/Custom/Loading/Shimmer";
import { SocialMediaList } from "../../../../../../../services/ccms/locationManagement/social_media/social_media_endpoints";

const currentDateTime = new Date()
        .toISOString()
        .replace(/[-:.T]/g, "")
        .slice(0, 14); // Format yyyyMMddHHmmss

const LocationSocialMedia = ({
    socialMediaHandles,
    isLoading,
    isSaving,
    onInsert,
    onUpdate, 
    locationID
}) => {
    const [showModal, setShowModal] = useState(false);
    const [editingSocialMedia, setEditingSocialMedia] = useState(null);
    const [formData, setFormData] = useState({
        mediaID: "",
        locationID: locationID,
        handle: "",
        fullLink: "",
        username: "",
        password: "",
        email: "",
        cellphone: "",
        createdDateTime: currentDateTime,
        modifiedDateTime: currentDateTime,
    });
    const [socialMediaList, setSocialMediaList] = useState([]);

    // Fetch social media list for dropdown
    useEffect(() => {
        const fetchSocialMediaList = async () => {
            try {
                const response = await SocialMediaList();
                setSocialMediaList(response.results || []);
            } catch (error) {
                console.error("Error fetching social media list:", error);
            }
        };
        fetchSocialMediaList();
    }, []);

    const handleShowModal = (socialMedia = null) => {
        setEditingSocialMedia(socialMedia);
        setFormData(
            socialMedia || {
                mediaID: "",
                locationID: locationID,
                handle: "",
                fullLink: "",
                username: "",
                password: "",
                email: "",
                cellphone: "",
                createdDateTime: currentDateTime,
                modifiedDateTime: currentDateTime,
            }
        );
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingSocialMedia(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingSocialMedia) {
            onUpdate(formData);
        } else {
            onInsert(formData);
        }
        handleCloseModal();
    };

    const columns = [
        {
            name: "",
            selector: (row) => {
                const media = socialMediaList.find((media) => media.mediaID === row.mediaID);
                return media ? (
                    <img
                        src={media.image}
                        alt={media.name}
                        style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                        }}
                    />
                ) : "N/A";
            },
            width: "100px",
        },
        {
            name: "Name",
            selector: (row) => {
                const media = socialMediaList.find((media) => media.mediaID === row.mediaID);
                return media ? media.name : "N/A";
            },
            sortable: true,
            // width: "200px",
        },
        {
            name: "Handle",
            selector: (row) => row.handle || "N/A",
            sortable: true,
        },
        {
            name: "Link",
            cell: (row) => (
                <a
                    href={row.fullLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary"
                >
                    <FontAwesomeIcon icon={faExternalLinkAlt} /> Visit
                </a>
            ),
            ignoreRowClick: true,
            // width: "100px",
        },
        {
            name: "",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleShowModal(row)}
                >
                    <FontAwesomeIcon icon={faPencil} /> Edit
                </Button>
            ),
            ignoreRowClick: true,
            width: "120px",
        },
    ];

    return (
        <div className="pt-3">
            <div className="d-flex justify-content-between mb-3">
                <h4 className="text-primary">Location Social Media</h4>
                <Button variant="primary" size="sm" onClick={() => handleShowModal()}>
                    <FontAwesomeIcon icon={faPlus} size="sm" /> Add Social Media
                </Button>
            </div>
            {isLoading ? (
                <Shimmer type="table" width="100%" />
            ) : (
                <DataTable
                    columns={columns}
                    data={socialMediaHandles}
                    pagination
                    highlightOnHover
                    striped
                />
            )}

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editingSocialMedia ? "Edit Social Media Handle" : "Add New Social Media Handle"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Social Media</Form.Label>
                            <Form.Select
                                name="mediaID"
                                value={formData.mediaID}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Social Media</option>
                                {socialMediaList.map((media) => (
                                    <option key={media.mediaID} value={media.mediaID}>
                                        {media.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Handle</Form.Label>
                            <Form.Control
                                type="text"
                                name="handle"
                                placeholder="Social Media Handle"
                                value={formData.handle}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Full Link</Form.Label>
                            <Form.Control
                                type="url"
                                name="fullLink"
                                placeholder="Full Social Media Link"
                                value={formData.fullLink}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Cellphone</Form.Label>
                            <Form.Control
                                type="text"
                                name="cellphone"
                                placeholder="Cellphone"
                                value={formData.cellphone}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="text-end">
                            <Button
                                variant="secondary"
                                onClick={handleCloseModal}
                                className="me-2"
                            >
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <span>Saving...</span>
                                ) : (
                                    <span>
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default LocationSocialMedia;
