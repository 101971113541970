import React, { useState } from "react";
import { Button, Card, Modal, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AsyncSelect from "react-select/async";
import { searchMember } from "../../../../../services/ccms/memberManagement/member/member_endpoint";
import avatarPlaceholder from "../../../../../images/empty_user.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { TextColor } from "../../../../../utilities/colorUtils";
import { wrap } from "framer-motion";

const AttendeesTab = ({ attendees, addAttendee }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedAttendee, setSelectedAttendee] = useState(null);
    const [selectedViewDetails, setSelectedViewDetails] = useState(null);

    // Load member options for AsyncSelect
    const loadMemberOptions = async (inputValue, callback) => {
        try {
            const results = await searchMember(inputValue);
            const options = results.results.map(member => ({
                value: member.userId,
                label: `${member.firstName} ${member.lastName} - (${member.email})`,
                avatar: member.avatar || avatarPlaceholder,
                firstName: member.firstName,
                surname: member.lastName,
                email: member.email,
            }));
            callback(options);
        } catch (error) {
            console.error("Error loading member options:", error);
        }
    };

    // Columns for the DataTable
    const columns = [
        {
            name: "",
            selector: (row) => (
                <img
                    src={row.avatar || avatarPlaceholder}
                    alt="Avatar"
                    className="rounded-circle"
                    style={{ width: "40px", height: "40px", objectFit: "cover" }}
                />
            ),
            width: "100px",
        },
        {
            name: "Name",
            selector: (row) => row.name + " " + row.surname,
            sortable: true,
            width: "200px",
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
            width: "250px",
        },
        {
            name: "Cellphone",
            selector: (row) => row.cellphone,
            sortable: true,
            width: "200px",
        },
        {
            name: "Comment",
            selector: (row) => row.comment || "No comment",
            wrap: true,
        },
        {
            name: "Status",
            selector: (row) => (
                // <span style={{ color: row.statusColor }}>{row.statusName}</span>
                <span className='badge' style={{ backgroundColor: row.statusColor }}>
                    <TextColor bgColor={row.statusColor} text={row.statusName} />
                </span>                
            ),
            width: "100px",
        },
        {
            name: "",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="xs"
                    onClick={() => handleViewDetails(row)}
                >
                   <FontAwesomeIcon icon={faEye} /> View
                </Button>
            ),
            width: "120px",
        },
    ];

    // Open View Modal
    const handleViewDetails = (attendee) => {
        setSelectedViewDetails(attendee);
        setShowViewModal(true);
    };

    // Handle Add Attendee
    const handleAddAttendee = () => {
        if (selectedAttendee) {
            addAttendee({
                meetingID: attendees.meetingID || 0,
                userID: selectedAttendee.value,
                name: selectedAttendee.firstName,
                surname: selectedAttendee.surname,
                email: selectedAttendee.email,
                avatar: selectedAttendee.avatar,
                statusID: 0,
                statusName: "Pending",
                statusColor: "grey",
            });
            setSelectedAttendee(null);
            setShowAddModal(false);
        }
    };

    return (
        <Card className="shadow-sm p-3">
            <div className="d-flex justify-content-between mb-3">
                <h4 className="text-primary">Attendees</h4>
                <Button variant="primary" size="sm" onClick={() => setShowAddModal(true)}>
                    <FontAwesomeIcon icon={faPlus} /> Attendee
                </Button>
            </div>
            <DataTable
                columns={columns}
                data={attendees}
                pagination
                highlightOnHover
            />

            {/* Add Attendee Modal */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Attendee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadMemberOptions}
                        onChange={setSelectedAttendee}
                        placeholder="Search for a member"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" onClick={() => setShowAddModal(false)}>
                        <FontAwesomeIcon icon={faClose} /> Cancel
                    </Button>
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={handleAddAttendee}
                        disabled={!selectedAttendee}
                    >
                        <FontAwesomeIcon icon={faSave} /> Add
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* View Details Modal */}
            {selectedViewDetails && (
                <Modal
                    show={showViewModal}
                    onHide={() => setShowViewModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Attendee Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Name:</strong> {selectedViewDetails.name}</p>
                        <p><strong>Surname:</strong> {selectedViewDetails.surname}</p>
                        <p><strong>Email:</strong> {selectedViewDetails.email}</p>
                        <p><strong>Cellphone:</strong> {selectedViewDetails.cellphone}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => setShowViewModal(false)}
                        >
                            <FontAwesomeIcon icon={faClose} /> Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Card>
    );
};

export default AttendeesTab;
