import { icon } from "@fortawesome/fontawesome-svg-core";

export const MenuList = [
    // Dashboard
    {   
        title: 'Dashboard',
        iconStyle: <i className="fas fa-home" />,
        to: '/app/dashboard',
        // rights: ['TaskManagementSystemAccess'],
    },
    // Tasks
    {   
        title: 'Tasks',
        iconStyle: <i className="fas fa-list" />,
        to: '/app/tasks',
        rights: ['TaskManagementSystemAccess'],
    },
    // My Tasks
    {   
        title: 'My Tasks',
        iconStyle: <i className="fas fa-tasks" />,
        to: '/app/my-tasks',
        // rights: ['TaskManagementSystemAccess'],
    },
    // My Team Tasks
    {   
        title: 'My Campus Tasks',
        iconStyle: <i className="fas fa-users" />,
        to: '/app/team-tasks',
        rights: ['CampusLeader'],
    },
    // Calendar
    {   
        title: 'Calendar',
        iconStyle: <i className="fas fa-calendar" />,
        to: '/app/calendar',
        // rights: ['TaskManagementSystemAccess'],
    },
    // HR Management
    {
        title: 'HR',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fas fa-bars" />,
        rights: ['TaskManagementAdmin', "StaffAccess"],
        content: [
        {
            title: 'My Details',
            to: '/app/my-details',
            rights: ['TaskManagementAdmin', "StaffAccess"],
        },
          {
            title: 'My Leaves',
            to: '/app/leaves',
            rights: ['TaskManagementAdmin', "StaffAccess"],
          },
          {
            title: 'Policies',
            to: '/app/policies',
            rights: ['TaskManagementAdmin', "StaffAccess"],
          },
        ],
      },
    // Meetings Management
    {
        title: 'Meetings',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fas fa-users" />,
        to: '/app/meetings',
        rights: ['TaskManagementAdmin'],
        content: [
            {
                // Meetings Overview
                title: 'Overview',
                to: '/app/meetings/overview',
                rights: ['TaskManagementAdmin'],
            },
            {
                // Meetings
                title: 'Meetings',
                to: '/app/meetings',
                iconStyle: <i className="fas fa-users" />,
                rights: ['TaskManagementAdmin'],
            },
            {
                // My Meetings
                title: 'My Meetings',
                to: '/app/meetings/my-meetings',
                rights: ['TaskManagementAdmin'],
            },
            {
                // Meeting Lookups
                title: 'Lookups',
                iconStyle: <i className="fas fa-bars" />,
                rights: ['TaskManagementAdmin'],
                to: '/app/meetings/lookups',
            },
            // Meeting Locations
            {
                title: 'Locations',
                to: '/app/meetings/locations',
                rights: ['TaskManagementAdmin'],
            },
            // // Meeting Venues
            // {
            //     title: 'Meeting Venues',
            //     to: '/app/meetings/lookups/meeting-venues',
            //     rights: ['TaskManagementAdmin'],
            // },
            // // Meeting Status
            // {
            //     title: 'Meeting Status',
            //     to: '/app/meetings/lookups/meeting-status',
            //     rights: ['TaskManagementAdmin'],
            // },
        ],
    },
    // Monitoring
    {
        title: 'Monitoring',
        iconStyle: <i className="fas fa-chart-line" />,
        to: '/app/monitoring',
        rights: ['TaskManagementAdmin'],
    },
    // Feedback
    {   
        title: 'Feedback',
        iconStyle: <i className="fas fa-comment" />,
        to: '/app/feedback',
        rights: ['TaskManagementAdmin'],
    },
    // Notifications
    {   
        title: 'Notifications',
        iconStyle: <i className="fas fa-bell" />,
        to: '/app/notifications',
        rights: ['TaskManagementSystemAccess'],
    },
    // // Reports
    // {   
    //     title: 'Reports',
    //     iconStyle: <i className="fas fa-newspaper" />,
    //     to: '/app/reports',
    //     rights: ['TaskManagementAdmin'],
    // },
    // Task Reports
    {
        title: 'Task Reports',
        classsChange: 'mm-collapse',
        iconStyle: <i className="fas fa-bars" />,
        rights: ['TaskManagementAdmin'],
        content: [
            {
                // Task Reports
                title: 'Tasks',
                to: '/app/reports/task-reports',
                rights: ['TaskManagementAdmin'],
            },
            // User Reports
            {
                title: 'Users',
                to: '/app/reports/user-reports',
                rights: ['TaskManagementAdmin'],
            },
            // Department Reports
            {
                title: 'Departments',
                to: '/app/reports/department-reports',
                rights: ['TaskManagementAdmin'],
            },
            // Location Reports
            {
                title: 'Campus',
                to: '/app/reports/location-reports',
                rights: ['TaskManagementAdmin'],
            }
        ],
    },
    // Profile
    {   
        title: 'Profile',
        iconStyle: <i className="fas fa-user" />,
        to: '/app/profile',
        rights: ['TaskManagementSystemAccess'],
    },
    // Departments
    {   
        title: 'Departments',
        iconStyle: <i className="fas fa-building" />,
        to: '/app/departments',
        rights: ['TaskManagementAdmin'],
    },
    // Departments
    {   
        title: 'Lookups',
        iconStyle: <i className="fas fa-bars" />,
        to: '/app/lookups',
        rights: ['TaskManagementAdmin'],
    },
    // // Lookups
    // {
    //     title: 'Lookups',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="fas fa-bars" />,
    //     rights: ['TaskManagementAdmin'],
    //     content: [
    //       {
    //         title: 'Task Status',
    //         to: '/app/lookups/task-status',
    //         rights: ['TaskManagementAdmin'],
    //       },
    //       {
    //         title: 'Email Template',
    //         to: '/app/lookups/email-template',
    //         rights: ['TaskManagementAdmin'],
    //       },
    //       {
    //         title: 'SMS Template',
    //         to: '/app/lookups/sms-template',
    //         rights: ['TaskManagementAdmin'],
    //       },
    //     ],
    //   },
    // Settings
    {   
        title: 'Settings',
        iconStyle: <i className="fas fa-cog" />,
        to: '/app/settings',
        rights: ['TaskManagementSystemAccess'],
    },
];
