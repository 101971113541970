import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import { getMeetingRequestList, saveMeetingRequest, updateMeetingRequest, deleteMeetingRequest } from '../../../../../../services/ccms/meetingsManagement';
import { DepartmentList } from '../../../../../../services/ccms/departmentManagement/department/department_endpoints';
import { getTextColor } from '../../../../../../utilities/colorUtils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { wrap } from 'framer-motion';

const MeetingRequestManagement = () => {
    const [requests, setRequests] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // For insert vs update control

    useEffect(() => {
        fetchRequests();
        fetchDepartments();
    }, []);

    const fetchRequests = async () => {
        setLoading(true);
        try {
            const response = await getMeetingRequestList();
            setRequests(response.results);
        } catch (error) {
            console.error('Error fetching meeting requests:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDepartments = async () => {
        try {
            const response = await DepartmentList();
            setDepartments(response.results);
        } catch (error) {
            console.error('Error fetching departments:', error);
        }
    };

    const handleEditClick = (request) => {
        setSelectedRequest(request);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleAddClick = () => {
        setSelectedRequest({ departmentID: '', name: '', description: '', color: '#000000' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleDeleteClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteMeetingRequest(id);
                    Swal.fire('Deleted!', 'Meeting request has been deleted.', 'success');
                    fetchRequests();
                } catch (error) {
                    console.error('Error deleting meeting request:', error);
                    Swal.fire('Error!', 'Failed to delete meeting request.', 'error');
                }
            }
        });
    };

    const handleSave = async () => {
        setUpdating(true);
        const action = isEditing ? 'Updating' : 'Adding';
        toast.info(`${action} meeting request...`);

        try {
            const response = isEditing 
                ? await updateMeetingRequest(selectedRequest) 
                : await saveMeetingRequest(selectedRequest);

            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                await fetchRequests();
                toast.success(`Meeting request ${isEditing ? 'updated' : 'added'} successfully`);
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error(`${action} meeting request failed:`, error);
            Swal.fire({
                title: 'Error',
                text: `An error occurred while ${action.toLowerCase()} the meeting request.`,
                icon: 'error',
            });
        } finally {
            setUpdating(false);
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedRequest(null);
    };

    const columns = [
        // { name: 'ID', selector: row => row.requestID, sortable: true, width: '100px' },
        { name: 'Department', 
            selector: row => {
                const filteredDepartments = departments.filter(d => d.departmentID === row.departmentID);
                if (filteredDepartments.length > 0) {
                  return filteredDepartments[0].name; // Access the departmentName property
                } else {
                  return ''; // Or some default value if no department is found
                }
              }, 
            sortable: true, width: '200px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px', wrap: true },
        { name: 'Color', cell: row => (
            <div style={{ backgroundColor: row.color, color: getTextColor(row.color), padding: '5px 10px', borderRadius: '5px' }}>
                {row.color}
            </div>
        ), sortable: true, width: '120px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => (
            <>
                <Button variant="warning" size="xs" onClick={() => handleEditClick(row)}>
                    <FontAwesomeIcon icon={faPencilAlt} size="xs" /> Edit
                </Button>{' '}
                <Button variant="danger" size="xs" onClick={() => handleDeleteClick(row.requestID)}>
                    <FontAwesomeIcon icon={faTrash} size="xs" /> Delete
                </Button>
            </>
        ), width: '250px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <Row className="mb-3">
                <Col>
                    <Button variant="success" size="xs" onClick={handleAddClick}>
                        <FontAwesomeIcon icon={faPlus} size="xs" /> Add Request
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={requests}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Meeting Request' : 'Add Meeting Request'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRequest && (
                        <Form>
                            <Form.Group controlId="departmentID">
                                <Form.Label>Department</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedRequest.departmentID}
                                    onChange={(e) => setSelectedRequest({ ...selectedRequest, departmentID: e.target.value })}
                                >
                                    <option value="">Select Department</option>
                                    {departments.map(dept => (
                                        <option key={dept.departmentID} value={dept.departmentID}>
                                            {dept.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedRequest.name} 
                                    onChange={(e) => setSelectedRequest({ ...selectedRequest, name: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedRequest.description} 
                                    onChange={(e) => setSelectedRequest({ ...selectedRequest, description: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="color">
                                <Form.Label>Color</Form.Label>
                                <Form.Control 
                                    type="color" 
                                    value={selectedRequest.color} 
                                    onChange={(e) => setSelectedRequest({ ...selectedRequest, color: e.target.value })} 
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSave} 
                        disabled={updating} 
                        size="sm"
                    >
                        {updating ? 'Saving...' : <><FontAwesomeIcon icon={faSave} /> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default MeetingRequestManagement;
