import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const StepTwo = ({
    meetingDetails,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
}) => {
    const stepRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(
        meetingDetails.StartDate ? new Date(meetingDetails.StartDate) : null
    );
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [setupDate, setSetupDate] = useState(
        meetingDetails.SetupDate ? new Date(meetingDetails.SetupDate) : null
    );
    const [cleanupDate, setCleanupDate] = useState(
        meetingDetails.CleanupDate ? new Date(meetingDetails.CleanupDate) : null
    );

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const handleProceed = () => {
        if (selectedDate && startTime && endTime) {
            const startDateTime = new Date(selectedDate);
            startDateTime.setHours(startTime.getHours(), startTime.getMinutes());

            const endDateTime = new Date(selectedDate);
            endDateTime.setHours(endTime.getHours(), endTime.getMinutes());

            handleMeetingDetailChange("startDate", startDateTime.toISOString());
            handleMeetingDetailChange("endDate", endDateTime.toISOString());
            handleMeetingDetailChange("setupDate", setupDate ? setupDate.toISOString() : null);
            handleMeetingDetailChange("cleanupDate", cleanupDate ? cleanupDate.toISOString() : null);

            handleNextStep();
        }
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: "800px" }}
            >
                <div className="card-body">
                    <div className="pt-3">
                        <div className="settings-form">
                            <h4 className="text-primary text-center">Choose Dates and Times</h4>
                            <Form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                        <Form.Label>Select Meeting Date</Form.Label>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={(date) => setSelectedDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            placeholderText="Select meeting date"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <Form.Label>Start Time</Form.Label>
                                        <DatePicker
                                            selected={startTime}
                                            onChange={(time) => setStartTime(time)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="hh:mm aa"
                                            className="form-control"
                                            placeholderText="Select start time"
                                            disabled={!selectedDate}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <Form.Label>End Time</Form.Label>
                                        <DatePicker
                                            selected={endTime}
                                            onChange={(time) => setEndTime(time)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="hh:mm aa"
                                            className="form-control"
                                            placeholderText="Select end time"
                                            disabled={!selectedDate || !startTime}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group mb-3 col-md-6">
                                        <Form.Label>Setup Date and Time (Optional)</Form.Label>
                                        <DatePicker
                                            selected={setupDate}
                                            onChange={(date) => setSetupDate(date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="form-control"
                                            placeholderText="Select setup date and time"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-6">
                                        <Form.Label>Cleanup Date and Time (Optional)</Form.Label>
                                        <DatePicker
                                            selected={cleanupDate}
                                            onChange={(date) => setCleanupDate(date)}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            className="form-control"
                                            placeholderText="Select cleanup date and time"
                                        />
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="secondary" onClick={handlePreviousStep}>
                                        <FontAwesomeIcon icon={faArrowLeft} /> Previous
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={handleProceed}
                                        disabled={!selectedDate || !startTime || !endTime}
                                    >
                                        Next <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepTwo;
