import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Card, Row, Col } from 'react-bootstrap';

const StepFour = ({
    meetingDetails,
    selectedLocation,
    locations,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
}) => {
    const stepRef = useRef(null);
    const [selectedVenue, setSelectedVenue] = useState(
        meetingDetails.meetingVenue?.venueID || ""
    );
    const [venueDetails, setVenueDetails] = useState(
        meetingDetails.meetingVenue?.details || ""
    );
    const [address, setAddress] = useState(
        meetingDetails.meetingVenue?.address || ""
    );

    const currentLocation = locations.find(
        (location) => location.locationID === Number(selectedLocation)
    );

    useEffect(() => {
        console.log('Selected Location:', selectedLocation);
        console.log('Locations:', locations);
        console.log('Current Location:', currentLocation);
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedLocation, locations, currentLocation]);

    const handleVenueSelection = (venueId) => {
        setSelectedVenue(venueId);
        handleMeetingDetailChange('meetingVenue', {
            ...meetingDetails.meetingVenue,
            venueID: venueId,
        });
    };

    const handleDetailChange = (key, value) => {
        handleMeetingDetailChange('meetingVenue', {
            ...meetingDetails.meetingVenue,
            [key]: value,
        });
    };

    const handleProceed = () => {
        handleNextStep();
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="mb-4 text-center">Choose Venue and Details</h4>

                    {meetingDetails.meetingVenue?.isInternal && (
                        <>
                            <h5 className="mb-3">Select a Venue</h5>
                            {currentLocation?.venues?.length > 0 ? (
                                <Row>
                                    {currentLocation.venues.map((venue) => (
                                        <Col md={6} key={venue.venueID} className="mb-3">
                                            <Card className="shadow p-0">
                                                <Card.Body>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="venue"
                                                        id={`venue-${venue.venueID}`}
                                                        value={venue.venueID}
                                                        checked={selectedVenue === venue.venueID}
                                                        onChange={() => handleVenueSelection(venue.venueID)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`venue-${venue.venueID}`}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <strong>{venue.name}</strong>
                                                        <p style={{ fontSize: '8pt' }}>
                                                            Capacity: {venue.minCapacity} - {venue.maxCapacity}
                                                        </p>
                                                    </label>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <p className="text-muted">No venues available for this location.</p>
                            )}
                        </>
                    )}

                    {(meetingDetails.meetingVenue?.isExternal ||
                        meetingDetails.meetingVenue?.isVirtual) && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label>Venue Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={address}
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                        handleDetailChange('address', e.target.value);
                                    }}
                                    placeholder="Enter venue address"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Additional Details</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={venueDetails}
                                    onChange={(e) => {
                                        setVenueDetails(e.target.value);
                                        handleDetailChange('details', e.target.value);
                                    }}
                                    placeholder="Enter additional details about the venue"
                                />
                            </Form.Group>
                        </>
                    )}

                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="secondary" onClick={handlePreviousStep}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleProceed}
                            disabled={
                                meetingDetails.meetingVenue?.isInternal && !selectedVenue
                            }
                        >
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepFour;
