import { getApiMeetings } from "../../APIServiceSetup";

const api_meetings = await getApiMeetings();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the meeting attachment list with caching
export const MeetingFileList = async (forceUpdate = false) => {
  const cacheKey = 'MeetingFileList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get the meeting attachment list view by meeting ID with caching
export const MeetingFileListView = async (meetingID, forceUpdate = false) => {
  const cacheKey = `MeetingFileListView_${meetingID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get(`/api/MeetingFile/ListView/${meetingID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting attachment by ID with caching
export const MeetingFileById = async (id, forceUpdate = false) => {
  const cacheKey = `MeetingFileById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get(`/api/MeetingFile/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting attachment with caching
export const MeetingFileGet = async (forceUpdate = false) => {
  const cacheKey = 'MeetingFileGet';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/Get');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert meeting attachment
export const MeetingFileInsert = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingFile/Insert', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update meeting attachment
export const MeetingFileUpdate = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingFile/Update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert or update meeting attachment
export const MeetingFileInsertOrUpdate = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingFile/InsertOrUpdate', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to delete meeting attachment
export const MeetingFileDelete = async (id) => {
  try {
    const response = await api_meetings.delete(`/api/MeetingFile/Delete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting attachment count with caching
export const MeetingFileCount = async (forceUpdate = false) => {
  const cacheKey = 'MeetingFileCount';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/Count');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to search meeting attachments with caching
export const MeetingFileSearch = async (query, forceUpdate = false) => {
  const cacheKey = `MeetingFileSearch_${JSON.stringify(query)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/Search', { params: { query } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get paginated meeting attachment list with caching
export const MeetingFilePaginatedList = async (page, size, forceUpdate = false) => {
  const cacheKey = `MeetingFilePaginatedList_${page}_${size}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/PaginatedList', { params: { page, size } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom meeting attachment list with caching
export const MeetingFileCustomList = async (params, forceUpdate = false) => {
  const cacheKey = `MeetingFileCustomList_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/CustomList', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom meeting attachment with caching
export const MeetingFileCustom = async (params, forceUpdate = false) => {
  const cacheKey = `MeetingFileCustom_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingFile/Custom', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};
