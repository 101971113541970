import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Spinner, Alert, Row, Col, ToggleButton, ToggleButtonGroup, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faPlus, faTable, faTh, faFilePdf, faFileWord, faFileExcel, faFileAlt, faFile } from '@fortawesome/free-solid-svg-icons';
import FileUploader from '../../../../components/Custom/Uploader/FileUploader';
import FilePreview from '../../../../components/Custom/Uploader/FilePreview';
import LazyLoad from 'react-lazyload';
import {
  TaskAttachmentInsert,
  TaskAttachmentListView,
  TaskAttachmentUpdate,
} from '../../../../../services/ccms/taskManagement/taskAttachements/task_attachment_endpoints';
import Shimmer from '../../../../components/Custom/Loading/Shimmer';

const TaskFiles = ({ taskID }) => {
  const [files, setFiles] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [previewFile, setPreviewFile] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fileData, setFileData] = useState({
    attachmentID: 0,
    taskID: taskID,
    fileName: '',
    fileURL: '',
    fileType: '',
    attachmentDate: new Date(),
    createdBy: localStorage.getItem('userID'),
  });
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [addAlertMessage, setAddAlertMessage] = useState(null);
  const [editAlertMessage, setEditAlertMessage] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);

  useEffect(() => {
    fetchFiles();
  }, [taskID]);

  const fetchFiles = async () => {
    try {
        setLoadingFile(true);   
      const response = await TaskAttachmentListView(taskID);
      if (response.status === 'Success') setFiles(response.results);
      setLoadingFile(false);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const fetchFileRefresher = async () => {
    try {
        setLoadingFile(true);   
      const response = await TaskAttachmentListView(taskID, true);
      if (response.status === 'Success') setFiles(response.results);
      setLoadingFile(false);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };


  const handlePreview = (file) => setPreviewFile(file);
  const handleClosePreview = () => setPreviewFile(null);

  const handleAddFile = async () => {
    if (!fileData.fileName || !fileData.fileURL) {
      setAddAlertMessage('Please provide both file name and URL.');
      return;
    }
    setAddAlertMessage(null);
    setIsAdding(true);

    try {
      const response = await TaskAttachmentInsert(fileData);
      if (response.status === 'Success') {
        fetchFileRefresher();
        setShowAddModal(false);
        resetFileData();
      }
    } catch (error) {
      console.error('Error adding file:', error);
    } finally {
      setIsAdding(false);
    }
  };

  const handleEditFile = async () => {
    if (!fileData.fileName || !fileData.fileURL) {
      setEditAlertMessage('Please provide both file name and URL.');
      return;
    }
    setEditAlertMessage(null);
    setIsEditing(true);

    try {
      const response = await TaskAttachmentUpdate(fileData);
      if (response.status === 'Success') {
        fetchFileRefresher();
        setShowEditModal(false);
        resetFileData();
      }
    } catch (error) {
      console.error('Error updating file:', error);
    } finally {
      setIsEditing(false);
    }
  };

  const resetFileData = () => {
    setFileData({
      attachmentID: 0,
      taskID: taskID,
      fileName: '',
      fileURL: '',
      fileType: '',
      attachmentDate: new Date(),
      createdBy: localStorage.getItem('userID'),
    });
  };

  const handleViewModeChange = (val) => setViewMode(val);

  const handleFileUploadSuccess = (fieldName, uploadedFileURL) => {
    const fileType = getMediaType(uploadedFileURL);
    setFileData((prevState) => ({
      ...prevState,
      [fieldName]: uploadedFileURL,
      fileType: fileType,
    }));

    setIsAdding(false);
    setIsEditing(false);
  };

  const handleFileUploadStart = () => {
    setIsAdding(true);
    setIsEditing(true);
  };

  const getMediaType = (fileURL) => {
    const extension = fileURL.split('.').pop().toLowerCase();
    const mimeTypes = {
      pdf: 'application/pdf',
      doc: 'application/msword',
      docx: 'application/msword',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ppt: 'application/vnd.ms-powerpoint',
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      txt: 'text/plain',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      mp4: 'video/mp4',
      webm: 'video/webm',
      ogg: 'video/ogg',
      mp3: 'audio/mpeg',
      wav: 'audio/wav',
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const renderFileGrid = () => (
    <Row className="g-3">
      {files.map((file) => (
        <Col key={file.attachmentID} xs={6} md={4} lg={3}>
          <div
            className="file-card"
            style={{
              backgroundColor: '#1d1d1d',
              borderRadius: '10px',
              overflow: 'hidden',
              position: 'relative',
              transition: 'transform 0.2s ease',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.03)')}
            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          >
            <LazyLoad height={180}>
              {file.fileType.startsWith('image/') ? (
                <img
                  src={file.fileURL}
                  alt={file.fileName}
                  className="img-fluid"
                  style={{ height: '180px', objectFit: 'cover', width: '100%' }}
                />
              ) : file.fileType.startsWith('video/') ? (
                <video
                  src={file.fileURL}
                  className="img-fluid"
                  style={{ height: '180px', objectFit: 'cover', width: '100%' }}
                />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: '180px',
                    backgroundColor: '#2b2b2b',
                  }}
                >
                  <FontAwesomeIcon icon={getIconForFileType(file.fileType)} size="3x" color="#fff" />
                </div>
              )}
            </LazyLoad>
  
            <div
              className="file-details"
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                padding: '8px',
              }}
            >
              <p className="file-name mb-1" style={{ fontWeight: 'bold', fontSize: '9pt' }}>
                {file.fileName}
              </p>
              <p className="file-date mb-0" style={{ fontSize: '8pt', color: '#ccc' }}>
                Uploaded by {`${file.createdByFirstName}`} | {new Date(file.attachmentDate).toLocaleDateString()}
              </p>
            </div>
  
            <div
              className="file-actions"
              style={{
                position: 'absolute',
                top: '40%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                gap: '8px',
                opacity: 0,
                transition: 'opacity 0.2s',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
              onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
            >
              <Button variant="link" size="sm" onClick={() => handlePreview(file)} className="text-white shadow">
                <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button variant="link" size="sm" onClick={() => openEditModal(file)} className="text-white shadow">
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
  

  const getIconForFileType = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return faFilePdf;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return faFileWord;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return faFileExcel;
      case 'text/plain':
        return faFileAlt;
      default:
        return faFile;
    }
  };

  const renderFileTable = () => (
    <DataTable
      columns={[
        { name: 'File Name', selector: (row) => row.fileName, sortable: true },
        { name: 'Type', selector: (row) => row.fileType, sortable: true },
        {
          name: 'Attachment Date',
          selector: (row) => new Date(row.attachmentDate).toLocaleDateString(),
          sortable: true,
        },
        {
          name: 'Uploaded By',
          selector: (row) => (
            <>
              <img src={row.createdByAvatar} alt="" className="rounded-circle me-2" width={30} height={30} />
              {`${row.createdByFirstName} ${row.createdByLastName}`}
            </>
          ),
          sortable: true,
        },
        {
          name: '',
          cell: (row) => (
            <>
              <Button variant="link" size="sm" onClick={() => handlePreview(row)}>
                <FontAwesomeIcon icon={faEye} /> View
              </Button>
              <Button variant="link" size="sm" onClick={() => openEditModal(row)}>
                <FontAwesomeIcon icon={faEdit} /> Edit
              </Button>
            </>
          ),
        },
      ]}
      data={files}
      pagination
    />
  );

  const openEditModal = (file) => {
    setFileData(file);
    setShowEditModal(true);
  };

  return (
    <Card className='card'>
      <Card.Header className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center pb-3">
            <div className="fillow-design">
              <a href="#">
                <i className="fas fa-file"></i>
              </a>
            </div>
            <div className="ms-3">
              <h4 className="fs-20 font-w700">Files</h4>
            </div>
        </div>
        {/* <h4 className='title'>Task Attachments</h4> */}
        <Button
          onClick={() => {
            resetFileData();
            setShowAddModal(true);
          }}
          size="sm"
        >
          <FontAwesomeIcon icon={faPlus} /> Add File
        </Button>
      </Card.Header>
      <Card.Body>
      <ToggleButtonGroup type="radio" name="viewMode" value={viewMode} onChange={handleViewModeChange} className="mb-3">
        <ToggleButton id="grid-view" value="grid" variant="outline-primary" size='sm'>
          <FontAwesomeIcon icon={faTh} /> Grid View
        </ToggleButton>
        <ToggleButton id="table-view" value="table" variant="outline-primary" size='sm'>
          <FontAwesomeIcon icon={faTable} /> Table View
        </ToggleButton>
      </ToggleButtonGroup>

        {loadingFile ? (<Shimmer type="grid" />) : (<>
            {viewMode === 'grid' ? renderFileGrid() : renderFileTable()}</>
        )}
    
      {previewFile && <FilePreview fileURL={previewFile.fileURL} onClose={handleClosePreview} />}
      </Card.Body>
      {/* Add File Modal */}
      <Modal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
          resetFileData();
          setAddAlertMessage(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addAlertMessage && <Alert variant="danger">{addAlertMessage}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                value={fileData.fileName}
                onChange={(e) => setFileData({ ...fileData, fileName: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>File Upload</Form.Label>
              <FileUploader 
                name="fileURL" 
                onSuccess={handleFileUploadSuccess}
                initialFileURL={fileData.fileURL}
                onStart={handleFileUploadStart}
                storageLocation="TaskFiles"
                holderText={taskID}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowAddModal(false);
              resetFileData();
              setAddAlertMessage(null);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddFile} disabled={isAdding}>
            {isAdding ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Edit File Modal */}
      <Modal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
          resetFileData();
          setEditAlertMessage(null);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editAlertMessage && <Alert variant="danger">{editAlertMessage}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                value={fileData.fileName}
                onChange={(e) => setFileData({ ...fileData, fileName: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>File Upload</Form.Label>
              <FileUploader 
                name="fileURL" 
                onSuccess={handleFileUploadSuccess}
                initialFileURL={fileData.fileURL}
                onStart={handleFileUploadStart}
                storageLocation="TaskFiles"
                holderText={fileData.taskID} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowEditModal(false);
              resetFileData();
              setEditAlertMessage(null);
            }}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleEditFile} disabled={isEditing}>
            {isEditing ? <Spinner animation="border" size="sm" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default TaskFiles;
