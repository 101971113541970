import React, { useEffect, useState, useRef } from "react";
import { Card, Col, Row, Button, Form, Spinner, Alert, Dropdown } from "react-bootstrap";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import {
    MeetingCommentInsert,
    MeetingCommentUpdate,
    MeetingCommentDelete,
    MeetingCommentCommentListView,
} from "../../../../../services/ccms/meetingsManagement";
import PerfectScrollbar from "react-perfect-scrollbar";
import profile from "../../../../../images/empty_user.jpg";
import { FriendlyDateTime } from "../../../../../utilities/convertDateTime";
import Swal from "sweetalert2";
import "react-perfect-scrollbar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

// Utility function to convert URLs to clickable links
const parseLinks = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g;
    const parts = text.split(urlRegex);
    const matches = text.match(urlRegex) || [];

    return parts.reduce((acc, part, index) => {
        acc.push(part); // Add the non-link text part
        if (matches[index]) {
            acc.push(
                <a
                    href={matches[index]}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    style={{ color: "blue", textDecoration: "underline" }}
                >
                    {matches[index]}
                </a>
            );
        }
        return acc;
    }, []);
};


const CommentsTab = ({ meetingID }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [editingComment, setEditingComment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const commentBoxRef = useRef(null);

    useEffect(() => {
        const fetchComments = async () => {
            setLoading(true);
            try {
                const commentListView = await MeetingCommentCommentListView(meetingID);
                setComments(commentListView.results || []); // Ensure comments is always an array
            } catch (err) {
                setError("Failed to load comments");
            } finally {
                setLoading(false);
            }
        };

        fetchComments();
    }, [meetingID]);

    const handleEditComment = (comment) => {
        setEditingComment(comment);
        setNewComment(comment.comment);
    };

    const handleCommentSubmit = async () => {
        if (newComment.trim() === "") return;

        setSubmitting(true);
        const commentData = {
            commentID: 0,
            meetingID,
            comment: newComment,
            commentDate: new Date(),
            createdBy: userDetails.userId,
        };

        try {
            const insertResponse = await MeetingCommentInsert(commentData);
            if (insertResponse.status === "Success") {
                const commentListView = await MeetingCommentCommentListView(meetingID, true);
                setComments(commentListView.results || []);
                setNewComment("");
            } else {
                setError("Failed to add comment");
            }
        } catch (err) {
            setError("Failed to add comment");
        } finally {
            setSubmitting(false);
        }
    };

    const handleUpdateComment = async () => {
        if (newComment.trim() === "") return;

        setSubmitting(true);
        const commentData = {
            ...editingComment,
            comment: newComment,
        };

        try {
            const updateResponse = await MeetingCommentUpdate(commentData);
            if (updateResponse.status === "Success") {
                const commentListView = await MeetingCommentCommentListView(meetingID, true);
                setComments(commentListView.results || []);
                setEditingComment(null);
                setNewComment("");
            } else {
                setError("Failed to update comment");
            }
        } catch (err) {
            setError("Failed to update comment");
        } finally {
            setSubmitting(false);
        }
    };

    const handleDeleteComment = async (commentData) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you really want to delete this comment?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        });

        if (result.isConfirmed) {
            setSubmitting(true);
            try {
                const deleteResponse = await MeetingCommentDelete(commentData);
                if (deleteResponse.status === "Success") {
                    const commentListView = await MeetingCommentCommentListView(meetingID, true);
                    setComments(commentListView.results || []);
                    Swal.fire("Deleted!", "Your comment has been deleted.", "success");
                } else {
                    setError("Failed to delete comment");
                }
            } catch (err) {
                setError("Failed to delete comment");
            } finally {
                setSubmitting(false);
            }
        }
    };

    const canEditOrDelete = (comment) => {
        const commentDate = new Date(comment.commentDate);
        const now = new Date();
        const diffMinutes = (now - commentDate) / (1000 * 60);
        return diffMinutes <= 10 && comment.createdBy === userDetails.userId;
    };

    return (
        <Card className="mt-0">
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center border-bottom flex-wrap">
                    <h4 className="fs-20 font-w700 text-primary">Comments</h4>
                </div>
                <div id="chartBox">
                    <PerfectScrollbar
                        className="chat-window"
                        style={{
                            padding: "2em 0",
                            maxHeight: "50vh",
                            minHeight: comments.length > 0 ? "20vh" : "auto",
                            overflowY: comments.length > 3 ? "auto" : "scroll",
                            overflowX: "hidden",
                        }}
                    >
                        <div id="ChatWindowInner" className="dz-scroll" ref={commentBoxRef}>
                            {loading ? (
                                <Shimmer type="item" />
                            ) : comments.length > 0 ? (
                                comments.map((comment) => (
                                    <Row
                                        key={comment.commentID}
                                        className={`mb-3 ${
                                            comment.createdBy === userDetails.userId ? "text-end" : ""
                                        }`}
                                    >
                                        {comment.createdBy === userDetails.userId ? (
                                            <div className="media mb-4 justify-content-end align-items-end">
                                                <div className="message-sent">
                                                    <span className="fs-12">Me</span>
                                                    <div className="d-flex align-items-center">
                                                        {canEditOrDelete(comment) && (
                                                            <Dropdown className="me-2">
                                                                <Dropdown.Toggle as="div" className="btn-link i-false">
                                                                    <i className="fas fa-ellipsis-v"></i>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                                    <Dropdown.Item onClick={() => handleEditComment(comment)}>
                                                                        Edit
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => handleDeleteComment(comment)}>
                                                                        Delete
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        )}
                                                        <div style={{ flex: 1 }}>
                                                            <p className="mb-1" style={{ whiteSpace: "pre-wrap" }}>
                                                                {parseLinks(comment.comment)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <span className="fs-12">
                                                        <FriendlyDateTime dateInput={comment.commentDate} />
                                                    </span>
                                                </div>
                                                <div className="image-box ms-sm-4 ms-2 mb-4">
                                                    <img
                                                        src={comment.createdByAvatar || profile}
                                                        alt=""
                                                        className="rounded-circle img-1"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="media my-4 justify-content-start align-items-start">
                                                <div className="image-box me-sm-4 me-2">
                                                    <img
                                                        src={comment.createdByAvatar || profile}
                                                        alt=""
                                                        className="rounded-circle img-1"
                                                    />
                                                </div>
                                                <div className="message-received">
                                                    <span className="fs-12">
                                                        {comment.createdByFirstName + " " + comment.createdByLastName}
                                                    </span>
                                                    <p className="mb-1 me-5" style={{ whiteSpace: "pre-wrap" }}>
                                                        {parseLinks(comment.comment)}
                                                    </p>
                                                    <span className="fs-12">
                                                        <FriendlyDateTime dateInput={comment.commentDate} />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Row>
                                ))
                            ) : (
                                <p className="text-center text-muted">No comments available.</p>
                            )}
                            {error && <Alert variant="danger">{error}</Alert>}
                        </div>
                    </PerfectScrollbar>
                </div>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col xs={10} md={10} className="text-center">
                        <Form.Group className="border-0 type-massage mt-3">
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Add a comment..."
                                style={{ resize: "none", overflow: "hidden" }}
                            />
                        </Form.Group>
                    </Col>
                    <Col
                        xs={2}
                        md={2}
                        className="d-flex align-items-end justify-content-end align-items-center"
                    >
                        <Button
                            variant="primary"
                            disabled={submitting}
                            onClick={editingComment ? handleUpdateComment : handleCommentSubmit}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} /> 
                            {submitting ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    {editingComment ? " Updating..." : " Sending..."}
                                </>
                            ) : (
                                editingComment ? " Update" :  " Send"
                            )}
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
        </Card>
    );
};

export default CommentsTab;
