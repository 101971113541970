import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus, faTrash,faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import { getMeetingStatusList, saveMeetingStatus, updateMeetingStatus, deleteMeetingStatus } from '../../../../../../services/ccms/meetingsManagement';
import { getTextColor } from '../../../../../../utilities/colorUtils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const MeetingStatusManagement = () => {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // For insert vs update control

    useEffect(() => {
        fetchStatuses();
    }, []);

    const fetchStatuses = async (forceUpdate = false) => {
        setLoading(true);
        try {
            const response = await getMeetingStatusList(forceUpdate);
            setStatuses(response.results);
        } catch (error) {
            console.error('Error fetching meeting statuses:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (status) => {
        setSelectedStatus(status);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleAddClick = () => {
        setSelectedStatus({ name: '', description: '', color: '#000000' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleDeleteClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteMeetingStatus(id);
                    Swal.fire('Deleted!', 'Meeting status has been deleted.', 'success');
                    fetchStatuses();
                } catch (error) {
                    console.error('Error deleting meeting status:', error);
                    Swal.fire('Error!', 'Failed to delete meeting status.', 'error');
                }
            }
        });
    };

    const handleSave = async () => {
        setUpdating(true);
        const action = isEditing ? 'Updating' : 'Adding';
        toast.info(`${action} meeting status...`);

        try {
            const response = isEditing 
                ? await updateMeetingStatus(selectedStatus) 
                : await saveMeetingStatus(selectedStatus);

            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                await fetchStatuses();
                toast.success(`Meeting status ${isEditing ? 'updated' : 'added'} successfully`);
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error(`${action} meeting status failed:`, error);
            Swal.fire({
                title: 'Error',
                text: `An error occurred while ${action.toLowerCase()} the meeting status.`,
                icon: 'error',
            });
        } finally {
            setUpdating(false);
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedStatus(null);
    };

    const columns = [
        { name: 'ID', selector: row => row.statusID, sortable: true, width: '100px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
        { name: 'Color', cell: row => (
            <div style={{ backgroundColor: row.color, color: getTextColor(row.color), padding: '5px 10px', borderRadius: '5px' }}>
                {row.color}
            </div>
        ), sortable: true, width: '120px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => (
            <>
                <Button variant="warning" size="xs" onClick={() => handleEditClick(row)}>
                    <FontAwesomeIcon icon={faPencilAlt}  size="xs" /> Edit
                </Button>
            </>
        ), width: '250px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <Row className="mb-3">
                {/* <Col>
                    <Button variant="success" size="sm" onClick={handleAddClick}>
                        <FontAwesomeIcon icon={faPlus} size="sm" /> Add New Status
                    </Button>
                </Col> */}
            </Row>

            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                
                </div>
                <Button size="xs" onClick={() => fetchStatuses(true)}>
                <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
            </div>

            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={statuses}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Meeting Status' : 'Add Meeting Status'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedStatus && (
                        <Form>
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedStatus.name} 
                                    onChange={(e) => setSelectedStatus({ ...selectedStatus, name: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={selectedStatus.description} 
                                    onChange={(e) => setSelectedStatus({ ...selectedStatus, description: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="color">
                                <Form.Label>Color</Form.Label>
                                <Form.Control 
                                    type="color" 
                                    value={selectedStatus.color} 
                                    onChange={(e) => setSelectedStatus({ ...selectedStatus, color: e.target.value })} 
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSave} 
                        disabled={updating} 
                        size="sm"
                    >
                        {updating ? 'Saving...' : <><FontAwesomeIcon icon={faSave} /> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default MeetingStatusManagement;
