import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faPlus, faTrash, faSave } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Form } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { searchMember } from '../../../../../../services/ccms/memberManagement/member/member_endpoint';

import avatar from "../../../../../../images/empty_user.jpg";

const StepSeven = ({
    meetingDetails,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
    isSubmitting,
    handleSubmit,
}) => {
    const stepRef = useRef(null);
    const [title, setTitle] = useState(meetingDetails.title || "");
    const [description, setDescription] = useState(meetingDetails.description || "");
    const [selectedAttendee, setSelectedAttendee] = useState(null);
    const [attendeesList, setAttendeesList] = useState(meetingDetails.meetingAttendees || []);

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const loadMemberOptions = async (inputValue, callback) => {
        try {
            const results = await searchMember(inputValue);
            const options = results.results.map(member => ({
                value: member.userId,
                label: `${member.firstName} ${member.lastName} - (${member.email})`,
                avatar: member.avatar,
                firstName: member.firstName,
                surname: member.lastName,
                email: member.email,
                userID: member.userId,
            }));
            callback(options);
        } catch (error) {
            console.error('Error loading member options:', error);
        }
    };

    const handleAddAttendee = () => {
        if (selectedAttendee) {
            // Data for display only
            const displayAttendee = {
                userID: selectedAttendee.value,
                avatar: selectedAttendee.avatar,
                firstName: selectedAttendee.firstName,
                surname: selectedAttendee.surname,
                email: selectedAttendee.email,
            };

            // Data for submission
            const submitAttendee = {
                meetingID: meetingDetails.meetingID || 0,
                userID: selectedAttendee.value,
                statusID: 0, // Default status
            };

            const updatedList = [...attendeesList, displayAttendee];
            setAttendeesList(updatedList);

            // Update the meeting details only with submission data
            const updatedSubmissionData = meetingDetails.meetingAttendees
                ? [...meetingDetails.meetingAttendees, submitAttendee]
                : [submitAttendee];
            handleMeetingDetailChange("meetingAttendees", updatedSubmissionData);

            // Clear the AsyncSelect
            setSelectedAttendee(null);
        }
    };

    const handleRemoveAttendee = (userID) => {
        const updatedList = attendeesList.filter((attendee) => attendee.userID !== userID);
        setAttendeesList(updatedList);

        const updatedSubmissionData = meetingDetails.meetingAttendees.filter(
            (attendee) => attendee.userID !== userID
        );
        handleMeetingDetailChange("meetingAttendees", updatedSubmissionData);
    };

    const handleTitleChange = (e) => {
        const value = e.target.value;
        setTitle(value);
        handleMeetingDetailChange("title", value);
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);
        handleMeetingDetailChange("description", value);
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="mb-4 text-center text-primary">Remaining Details and Attendees</h4>

                    <Form className="mb-4">
                        <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Enter meeting title"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={description}
                                onChange={handleDescriptionChange}
                                placeholder="Enter meeting description"
                            />
                        </Form.Group>
                    </Form>

                    <h5 className="mb-3">Attendees</h5>
                    <div className="d-flex align-items-center mb-4 gap-3">
                        <div style={{ flex: 1 }}>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadMemberOptions}
                                onChange={setSelectedAttendee}
                                placeholder="Search for a member"
                                value={null} // Clear the selected option after addition
                            />
                        </div>
                        <Button
                            variant="primary"
                            onClick={handleAddAttendee}
                            disabled={!selectedAttendee}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Add Attendee
                        </Button>
                    </div>

                    {attendeesList.length > 0 && (
                        <div className="d-flex flex-wrap gap-3">
                            {attendeesList.map((attendee, index) => (
                                <Card
                                    className="shadow"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        padding: "0.5em",
                                        width: "100%",
                                        maxWidth: "350px",
                                    }}
                                    key={index}
                                >
                                    <div>
                                        <img
                                            src={attendee.avatar || avatar} // Default avatar fallback
                                            alt="Avatar"
                                            className="rounded-circle"
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginLeft: "1em", flex: 1 }}>
                                        <div style={{ fontSize: "9pt", fontWeight: "700" }}>
                                            {attendee.firstName} {attendee.surname}
                                        </div>
                                        <div style={{ fontSize: "8pt", color: "#666" }}>
                                            {attendee.email}
                                        </div>
                                    </div>
                                    <Button
                                        variant="outline-danger"
                                        size="xs"
                                        style={{ marginLeft: "auto" }}
                                        onClick={() => handleRemoveAttendee(attendee.userID)}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Card>
                            ))}
                        </div>
                    )}

                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="secondary" onClick={handlePreviousStep}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                        </Button>
                        <Button
                            variant="success"
                            onClick={handleSubmit}
                            disabled={isSubmitting || !title}
                        >
                            {isSubmitting ? 'Submitting...' : <><FontAwesomeIcon icon={faSave} /> Submit</>}
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepSeven;
