import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Alert, ListGroup } from 'react-bootstrap';

const StepDisclaimer = ({ handleNextStep }) => {
    const stepRef = useRef(null);
    const [agreed, setAgreed] = useState(false);

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleAgreementChange = (e) => {
        setAgreed(e.target.checked);
    };

    const handleProceed = () => {
        if (agreed) handleNextStep();
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="mb-4 text-center">Important Information Before You Proceed</h4>
                    <Alert variant="warning">
                        <p className="mb-0 text-center">
                            Please carefully read the following information before booking a meeting:
                        </p>
                    </Alert>
                    <ListGroup variant="flush" className="mb-4">
                        <ListGroup.Item>
                            <strong>Venue Booking Policy:</strong> Your meeting venue booking is subject to change in the event of an emergency. You are responsible for rescheduling the meeting and notifying all participants about any changes.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Calendar Check:</strong> Please ensure you review the calendar before making a booking to confirm that your preferred date and time are available. This will facilitate prompt confirmation of your meeting.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>Responsibility:</strong> It is your duty to ensure that all involved parties are informed about changes or updates to the meeting details.
                        </ListGroup.Item>
                    </ListGroup>
                    <Form.Group controlId="formAgreement" className="mb-4">
                        <Form.Check
                            type="checkbox"
                            label="I have read and understood the above information."
                            checked={agreed}
                            onChange={handleAgreementChange}
                        />
                    </Form.Group>
                    <div className="text-center">
                        <Button
                            variant="primary"
                            onClick={handleProceed}
                            disabled={!agreed}
                        >
                            Proceed <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepDisclaimer;
