import React, { useState, useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import Shimmer from "../../../../components/Custom/Loading/Shimmer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const VenueTab = ({ meetingData = {}, isLoading, updateMeetingVenue }) => {
    const initialVenueData = meetingData || {
        meetingVenueID: 0,
        meetingID: 0,
        venueID: 0,
        venueName: "",
        isExtenal: false,
        isVirtual: false,
        isInternal: true,
        address: "",
        details: "",
        createdDateTime: 0,
        modifiedDateTime: 0,
    };

    const [venueData, setVenueData] = useState(initialVenueData);

    useEffect(() => {
        // Update venueData when meetingData changes
        setVenueData(meetingData || initialVenueData);
    }, [meetingData]);

    const handleChange = (field, value) => {
        setVenueData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const handleTypeChange = (type) => {
        setVenueData({
            ...venueData,
            isExtenal: type === "external",
            isVirtual: type === "virtual",
            isInternal: type === "internal",
        });
    };

    const handleSave = async () => {
        try {
            await updateMeetingVenue(venueData);
        } catch (error) {
            console.error("Failed to update meeting venue:", error);
        }
    };

    if (isLoading) {
        return <Shimmer type="article" />;
    }

    return (
        <Card className="shadow-sm p-3">
            <h4 className="text-primary">Venue Details</h4>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group className="mb-3">
                    <Form.Label>Venue Type</Form.Label>
                    <div>
                        <Form.Check
                            inline
                            label="Internal"
                            type="radio"
                            checked={venueData.isInternal}
                            onChange={() => handleTypeChange("internal")}
                        />
                        <Form.Check
                            inline
                            label="External"
                            type="radio"
                            checked={venueData.isExtenal}
                            onChange={() => handleTypeChange("external")}
                        />
                        <Form.Check
                            inline
                            label="Virtual"
                            type="radio"
                            checked={venueData.isVirtual}
                            onChange={() => handleTypeChange("virtual")}
                        />
                    </div>
                </Form.Group>
                {venueData.isInternal && (
                    <Form.Group className="mb-3">
                        <Form.Label>Venue Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={venueData.venueName || ""}
                            readOnly
                        />
                    </Form.Group>
                )}
                {venueData.isExtenal && (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Venue Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={venueData.venueName || ""}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={venueData.address}
                                onChange={(e) => handleChange("address", e.target.value)}
                            />
                        </Form.Group>
                    </>
                )}
                {venueData.isVirtual && (
                    <Form.Group className="mb-3">
                        <Form.Label>Details</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={venueData.details}
                            onChange={(e) => handleChange("details", e.target.value)}
                        />
                    </Form.Group>
                )}
                <hr />
                <Button
                    variant="primary"
                    size="sm"
                    onClick={handleSave}
                    disabled={isLoading}
                >
                    <FontAwesomeIcon icon={faSave} /> Save Changes
                </Button>
            </Form>
        </Card>
    );
};

export default VenueTab;
