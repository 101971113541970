import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

// Cache Helper Functions
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

const invalidateCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
};

// API Functions with Caching

// ServiceTime List
const SERVICE_TIME_LIST_CACHE = 'serviceTimeListCache';
export const ServiceTimeList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(SERVICE_TIME_LIST_CACHE)) {
        return getCache(SERVICE_TIME_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/ServiceTime/List');
        setCache(SERVICE_TIME_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// ServiceTime List By Location
export const ServiceTimeListByLocation = async (locationId, forceUpdate = false) => {
    const cacheKey = `serviceTimeListByLocationCache_${locationId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/ServiceTime/ListByLocation/${locationId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// ServiceTime By ID
export const ServiceTime = async (id, forceUpdate = false) => {
    const cacheKey = `serviceTimeByIdCache_${id}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/ServiceTime/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Insert ServiceTime
export const InsertServiceTime = async (serviceTime) => {
    try {
        const response = await api_locations.post('/api/ServiceTime/Insert', serviceTime);
        invalidateCache(SERVICE_TIME_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update ServiceTime
export const UpdateServiceTime = async (serviceTime) => {
    try {
        const response = await api_locations.post('/api/ServiceTime/Update', serviceTime);
        invalidateCache(SERVICE_TIME_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get All ServiceTimes
export const GetAllServiceTimes = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(SERVICE_TIME_LIST_CACHE)) {
        return getCache(SERVICE_TIME_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/ServiceTime');
        setCache(SERVICE_TIME_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Active ServiceTimes By Location
export const ActiveServiceTimesByLocation = async (locationId, forceUpdate = false) => {
    const cacheKey = `activeServiceTimesByLocationCache_${locationId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/ServiceTime/ActiveByLocation/${locationId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Set End Time for ServiceTime
export const SetEndTime = async (serviceTimeId, endTime) => {
    try {
        const response = await api_locations.post(`/api/ServiceTime/SetEndTime/${serviceTimeId}`, endTime);
        invalidateCache(SERVICE_TIME_LIST_CACHE); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
