import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import Shimmer from "../../../../../../components/Custom/Loading/Shimmer";

const formatTime = (dateTime) => {
    if (!dateTime) return "N/A";
    const date = new Date(dateTime);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const currentDateTime = new Date()
        .toISOString()
        .replace(/[-:.T]/g, "")
        .slice(0, 14); // Format yyyyMMddHHmmss

const LocationServiceTimes = ({ serviceTimes, isLoading, isSaving, onInsert, onUpdate, locationID }) => {
    const [showModal, setShowModal] = useState(false);
    const [editingServiceTime, setEditingServiceTime] = useState(null);
    const [formData, setFormData] = useState({
        serviceTimeID: 0,
        locationID: locationID,
        name: "",
        startTime: "",
        endTime: "",
        isActive: false,
        description: "",
        color: "",
        createdDateTime: currentDateTime,
        modifiedDateTime: currentDateTime,
    });

    const handleShowModal = (serviceTime = null) => {
        setEditingServiceTime(serviceTime);
        setFormData(
            serviceTime || {
                serviceTimeID: 0,
                locationID: locationID,
                name: "",
                startTime: "",
                endTime: "",
                isActive: false,
                description: "",
                color: "",
                createdDateTime: currentDateTime,
                modifiedDateTime: currentDateTime,
            }
        );
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setEditingServiceTime(null);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (editingServiceTime) {
            onUpdate(formData);
        } else {
            onInsert(formData);
        }
        handleCloseModal();
    };

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name || "N/A",
            sortable: true,
            width: "200px",
        },
        {
            name: "Description",
            selector: (row) => row.description || "N/A",
            sortable: true,
            wrap: true,
        },
        {
            name: "Start Time",
            selector: (row) => row.startTime,
            sortable: true,
            width: "150px",
        },
        {
            name: "End Time",
            selector: (row) => row.endTime,
            sortable: true,
            width: "150px",
        },
        {
            name: "",
            cell: (row) => (
                <Button
                    variant="outline-primary"
                    size="xs"
                    onClick={() => handleShowModal(row)}
                >
                    <FontAwesomeIcon icon={faPencil} /> Edit
                </Button>
            ),
            ignoreRowClick: true,
            width: "120px",
        },
    ];

    return (
        <div className="pt-3">
            <div className="d-flex justify-content-between mb-3">
                <h4 className="text-primary">Service Times</h4>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleShowModal()}
                >
                    <FontAwesomeIcon icon={faPlus} size="sm" /> Add Times
                </Button>
            </div>
            {isLoading ? (
                <Shimmer type="table" width="100%" />
            ) : (
                <DataTable
                    columns={columns}
                    data={serviceTimes}
                    pagination
                    highlightOnHover
                    striped
                />
            )}

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editingServiceTime ? "Edit Service Time" : "Add New Service Time"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <input type="hidden" name="serviceTimeID" value={formData.serviceTimeID} />
                        <input
                            type="hidden"
                            name="createdDateTime"
                            value={formData.createdDateTime}
                        />
                        <input
                            type="hidden"
                            name="modifiedDateTime"
                            value={formData.modifiedDateTime}
                        />
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Service Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                placeholder="Description"
                                value={formData.description}
                                onChange={handleChange}
                                rows={3}
                            />
                        </Form.Group>
                        <div className="row">
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="startTime"
                                    value={formData.startTime}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3 col-md-6">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                    type="time"
                                    name="endTime"
                                    value={formData.endTime}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Is Active"
                                name="isActive"
                                checked={formData.isActive}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="text-end">
                            <Button
                                variant="secondary"
                                onClick={handleCloseModal}
                                className="me-2"
                            >
                                <FontAwesomeIcon icon={faTimes} /> Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSaving}
                            >
                                {isSaving ? (
                                    <span>Saving...</span>
                                ) : (
                                    <span>
                                        <FontAwesomeIcon icon={faSave} /> Save
                                    </span>
                                )}
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default LocationServiceTimes;
