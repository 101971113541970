import React, { useState, useEffect, Fragment } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faSave, faTimes, faPlus, faTrash,faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import Shimmer from '../../../../../components/Custom/Loading/Shimmer';
import { getMeetingTypeList, saveMeetingType, updateMeetingType, deleteMeetingType } from '../../../../../../services/ccms/meetingsManagement';
import { getTextColor } from '../../../../../../utilities/colorUtils';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { DepartmentList } from '../../../../../../services/ccms/departmentManagement/department/department_endpoints';

const MeetingTypeManagement = () => {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedType, setSelectedType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // For insert vs update control

    useEffect(() => {
        fetchTypes();
    }, []);

    const fetchTypes = async (forceUpdate = false) => {
        setLoading(true);
        try {
            const response = await getMeetingTypeList(forceUpdate);
            setTypes(response.results);
        } catch (error) {
            console.error('Error fetching meeting types:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = (type) => {
        setSelectedType(type);
        setIsEditing(true);
        setShowModal(true);
    };

    const handleAddClick = () => {
        setSelectedType({ name: '', description: '', color: '#000000' });
        setIsEditing(false);
        setShowModal(true);
    };

    const handleDeleteClick = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "This action cannot be undone!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteMeetingType(id);
                    Swal.fire('Deleted!', 'Meeting type has been deleted.', 'success');
                    fetchTypes();
                } catch (error) {
                    console.error('Error deleting meeting type:', error);
                    Swal.fire('Error!', 'Failed to delete meeting type.', 'error');
                }
            }
        });
    };

    const handleSave = async () => {
        setUpdating(true);
        const action = isEditing ? 'Updating' : 'Adding';
        toast.info(`${action} meeting type...`);

        try {
            const response = isEditing 
                ? await updateMeetingType(selectedType) 
                : await saveMeetingType(selectedType);

            if (response.status === 'Success') {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'success',
                });
                await fetchTypes();
                toast.success(`Meeting type ${isEditing ? 'updated' : 'added'} successfully`);
            } else {
                Swal.fire({
                    title: response.title,
                    text: response.message,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error(`${action} meeting type failed:`, error);
            Swal.fire({
                title: 'Error',
                text: `An error occurred while ${action.toLowerCase()} the meeting type.`,
                icon: 'error',
            });
        } finally {
            setUpdating(false);
            setShowModal(false);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedType(null);
    };

    const columns = [
        { name: 'ID', selector: row => row.typeID, sortable: true, width: '100px' },
        { name: 'Name', selector: row => row.name, sortable: true, width: '200px' },
        { name: 'Color', cell: row => (
            <div style={{ backgroundColor: row.color, color: getTextColor(row.color), padding: '5px 10px', borderRadius: '5px' }}>
                {row.color}
            </div>
        ), sortable: true, width: '120px' },
        { name: 'Description', selector: row => row.description, sortable: true },
        { name: '', cell: row => (
            <>
                <Button variant="warning" size="xs" onClick={() => handleEditClick(row)}>
                    <FontAwesomeIcon icon={faPencilAlt} size='sm' /> Edit
                </Button>{' '}
                <Button variant="danger" size="xs" onClick={() => handleDeleteClick(row.id)}>
                    <FontAwesomeIcon icon={faTrash} size='sm' /> Delete
                </Button>
            </>
        ), width: '260px' },
    ];

    if (loading) {
        return <Shimmer type="sections" />;
    }

    return (
        <Fragment>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                    <Button variant="success" size='sm' onClick={handleAddClick}>
                        <FontAwesomeIcon icon={faPlus} size='sm' /> Add Type
                    </Button>
                </div>
                <Button size="xs" onClick={() => fetchTypes(true)}>
                    <FontAwesomeIcon icon={faSyncAlt} />
                </Button>
            </div>
            {/* <Row className="mb-3">
                <Col>
                    
                </Col>
            </Row> */}

            <Row>
                <Col>
                    <DataTable
                        columns={columns}
                        data={types}
                        pagination
                        highlightOnHover
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Edit Meeting Type' : 'Add Meeting Type'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedType && (
                        <Form>
                            <Form.Group controlId="name" className='mb-3'>
                                <Form.Label className="form-label">Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    className="form-control"
                                    value={selectedType.name} 
                                    onChange={(e) => setSelectedType({ ...selectedType, name: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="description" className='mb-3'>
                                <Form.Label className="form-label">Description</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    className="form-control"
                                    value={selectedType.description} 
                                    onChange={(e) => setSelectedType({ ...selectedType, description: e.target.value })} 
                                />
                            </Form.Group>
                            <Form.Group controlId="color" className='mb-3'>
                                <Form.Label className="form-label">Color</Form.Label>
                                <Form.Control 
                                    type="color" 
                                    value={selectedType.color} 
                                    onChange={(e) => setSelectedType({ ...selectedType, color: e.target.value })} 
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} size="sm">
                        <FontAwesomeIcon icon={faTimes} /> Close
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSave} 
                        disabled={updating} 
                        size="sm"
                    >
                        {updating ? 'Saving...' : <><FontAwesomeIcon icon={faSave} /> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default MeetingTypeManagement;
