import { getApiMeetings } from "../../APIServiceSetup";

const api_meetings = await getApiMeetings();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

// Function to get the meeting comment list with caching
export const MeetingCommentList = async (forceUpdate = false) => {
  const cacheKey = 'MeetingCommentList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting comment by ID with caching
export const MeetingCommentById = async (id, forceUpdate = false) => {
  const cacheKey = `MeetingCommentById_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get(`/api/MeetingComment/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting comment with caching
export const MeetingCommentGet = async (forceUpdate = false) => {
  const cacheKey = 'MeetingCommentGet';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/Get');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert meeting comment
export const MeetingCommentInsert = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingComment/Insert', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to update meeting comment
export const MeetingCommentUpdate = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingComment/Update', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to insert or update meeting comment
export const MeetingCommentInsertOrUpdate = async (data) => {
  try {
    const response = await api_meetings.post('/api/MeetingComment/InsertOrUpdate', data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to delete meeting comment
export const MeetingCommentDelete = async (data) => {
  try {
    const response = await api_meetings.post(`/api/MeetingComment/Delete`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting comment count with caching
export const MeetingCommentCount = async (forceUpdate = false) => {
  const cacheKey = 'MeetingCommentCount';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/Count');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to search meeting comments with caching
export const MeetingCommentSearch = async (query, forceUpdate = false) => {
  const cacheKey = `MeetingCommentSearch_${JSON.stringify(query)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/Search', { params: { query } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get paginated meeting comment list with caching
export const MeetingCommentPaginatedList = async (page, size, forceUpdate = false) => {
  const cacheKey = `MeetingCommentPaginatedList_${page}_${size}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/PaginatedList', { params: { page, size } });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom meeting comment list with caching
export const MeetingCommentCustomList = async (params, forceUpdate = false) => {
  const cacheKey = `MeetingCommentCustomList_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/CustomList', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get custom meeting comment with caching
export const MeetingCommentCustom = async (params, forceUpdate = false) => {
  const cacheKey = `MeetingCommentCustom_${JSON.stringify(params)}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get('/api/MeetingComment/Custom', { params });
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Function to get meeting comment list view by meeting ID with caching
export const MeetingCommentCommentListView = async (meetingID, forceUpdate = false) => {
  const cacheKey = `MeetingCommentCommentListView_${meetingID}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_meetings.get(`/api/MeetingComment/CommentListView/${meetingID}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};
