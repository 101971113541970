import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Utility function to check and set cache
const checkCache = (cacheKey) => {
  const now = Date.now();
  if (cache[cacheKey] && (now - cache[cacheKey].timestamp) < CACHE_DURATION) {
    return cache[cacheKey].data;
  }
  return null;
};

const setCache = (cacheKey, data) => {
  cache[cacheKey] = { data, timestamp: Date.now() };
};

const invalidateCache = (cacheKey) => {
  if (cache[cacheKey]) {
    delete cache[cacheKey];
  }
};

// Component for "/api/Location/List"
export const LocationList = async (forceUpdate = false) => {
  const cacheKey = 'locationList';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get('/api/Location/List');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch location list: ${error.message}`);
  }
};

// Component for "/api/Location/{id}"
export const LocationById = async (id, forceUpdate = false) => {
  const cacheKey = `location_${id}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get(`/api/Location/${id}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch location by ID: ${error.message}`);
  }
};

// Component for "/api/Location/Insert"
export const InsertLocation = async (location) => {
  try {
    const response = await api_locations.post('/api/Location/Insert', location);
    invalidateCache('locationList'); // Invalidate location list cache after insert
    invalidateCache('allLocations'); // Invalidate all locations cache
    return response.data;
  } catch (error) {
    throw new Error(`Failed to insert location: ${error.message}`);
  }
};

// Component for "/api/Location/Update"
export const UpdateLocation = async (location) => {
  try {
    const response = await api_locations.post('/api/Location/Update', location);
    invalidateCache('locationList'); // Invalidate location list cache after update
    invalidateCache('allLocations'); // Invalidate all locations cache
    invalidateCache(`location_${location.locationID}`); // Invalidate specific location cache
    return response.data;
  } catch (error) {
    throw new Error(`Failed to update location: ${error.message}`);
  }
};

// Component for "/api/Location"
export const AllLocations = async (forceUpdate = false) => {
  const cacheKey = 'allLocations';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get('/api/Location');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch all locations: ${error.message}`);
  }
};

// Component for "/api/Location/SetActive/{locationId}/{isActive}"
export const SetLocationActive = async (locationId, isActive) => {
  try {
    const response = await api_locations.post(`/api/Location/SetActive/${locationId}/${isActive}`);
    invalidateCache('locationList'); // Invalidate location list cache after update
    invalidateCache('allLocations'); // Invalidate all locations cache
    invalidateCache(`location_${locationId}`); // Invalidate specific location cache
    return response.data;
  } catch (error) {
    throw new Error(`Failed to set location active status: ${error.message}`);
  }
};

// Component for "/api/LocationLeader/GetByMemberId/{memberId}"
export const GetByMemberId = async (memberId, forceUpdate = false) => {
  const cacheKey = `member_locations_${memberId}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get(`/api/LocationLeader/GetByMemberId/${memberId}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch locations by member ID: ${error.message}`);
  }
};

// Component for "/api/Location/ListView"
export const LocationListView = async (forceUpdate = false) => {
  const cacheKey = 'locationListView';
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get('/api/Location/ListView');
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch location list view: ${error.message}`);
  }
};

// Component for "/api/Location/GetLocationModelView/{locationId}"
export const GetLocationModelView = async (locationId, forceUpdate = false) => {
  const cacheKey = `locationModelView_${locationId}`;
  if (!forceUpdate) {
    const cachedData = checkCache(cacheKey);
    if (cachedData) return cachedData;
  }
  try {
    const response = await api_locations.get(`/api/Location/GetLocationModelView/${locationId}`);
    setCache(cacheKey, response.data);
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch location model view: ${error.message}`);
  }
};
