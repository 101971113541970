import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, Row, Col, Card } from 'react-bootstrap';

const StepThree = ({
    meetingDetails,
    locations,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
    setSelectedLocation, // Function to set the selected location for the next step
}) => {
    const stepRef = useRef(null);

    const [attendees, setAttendees] = useState(meetingDetails.noOfAttendees || "");
    const [venueType, setVenueType] = useState(
        meetingDetails.meetingVenue?.isExtenal
            ? "External"
            : meetingDetails.meetingVenue?.isVirtual
            ? "Virtual"
            : meetingDetails.meetingVenue?.isInternal
            ? "Internal"
            : ""
    );

    const [selectedLocation, setLocalSelectedLocation] = useState("");

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleAttendeesChange = (e) => {
        setAttendees(e.target.value);
        handleMeetingDetailChange('noOfAttendees', e.target.value);
    };

    const handleVenueTypeChange = (type) => {
        setVenueType(type);
        handleMeetingDetailChange('meetingVenue', {
            ...meetingDetails.meetingVenue,
            isExtenal: type === "External",
            isVirtual: type === "Virtual",
            isInternal: type === "Internal",
        });
    };

    const handleLocationChange = (locationId) => {
        setLocalSelectedLocation(locationId);
        setSelectedLocation(locationId); // Pass to parent for the next step
    };

    const handleProceed = () => {
        handleNextStep();
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="mb-4 text-center">Number of Attendees and Venue</h4>
                    <Form.Group className="mb-4">
                        <Form.Label>Number of Attendees (Optional)</Form.Label>
                        <Form.Control
                            type="number"
                            value={attendees}
                            onChange={handleAttendeesChange}
                            placeholder="Enter number of attendees or leave blank"
                        />
                    </Form.Group>

                    <h5 className="mb-3">Choose Venue Type</h5>
                    <Row className="mb-4">
                        <Col md={4}>
                            <div className="form-check">
                                
                                <Card className='shadow p-0'>
                                    <Card.Body>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="venueType"
                                            id="venueInternal"
                                            value="Internal"
                                            checked={venueType === "Internal"}
                                            onChange={() => handleVenueTypeChange("Internal")}
                                        />
                                        <label className="form-check-label" htmlFor="venueInternal">                                        
                                            <strong>Internal</strong>
                                            <br/>
                                            <span style={{fontSize: '8pt'}}>Meetings held at an internal location.</span>                                        
                                        </label>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-check">
                                <Card className='shadow p-0'>
                                    <Card.Body>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="venueType"
                                            id="venueVirtual"
                                            value="Virtual"
                                            checked={venueType === "Virtual"}
                                            onChange={() => handleVenueTypeChange("Virtual")}
                                        />
                                        <label className="form-check-label" htmlFor="venueVirtual">
                                            <strong>Virtual</strong>
                                            <p style={{fontSize: '8pt'}}>Meetings held virtually via online platforms.</p>
                                        </label>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-check">
                            <Card className='shadow p-0'>
                            <Card.Body>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="venueType"
                                    id="venueExternal"
                                    value="External"
                                    checked={venueType === "External"}
                                    onChange={() => handleVenueTypeChange("External")}
                                />
                                <label className="form-check-label" htmlFor="venueExternal">
                                    <strong>External</strong>
                                    <p style={{fontSize: '8pt'}}>Meetings held at an external location.</p>
                                </label>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>

                    {venueType === "Internal" && (
                        <Form.Group className="mb-4">
                            <Form.Label>Select Internal Location</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedLocation}
                                onChange={(e) => handleLocationChange(e.target.value)}
                            >
                                <option value="">Choose a location</option>
                                {locations.map((location) => (
                                    <option key={location.locationID} value={location.locationID}>
                                        {location.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    )}

                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="secondary" onClick={handlePreviousStep}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleProceed}
                            disabled={venueType === "Internal" && !selectedLocation}
                        >
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepThree;
