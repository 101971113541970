import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faMapMarkerAlt, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Lookups_Meetings = [
  {
    title: 'Meeting Type',
    description: 'Manage the different types for meetings in the system.',
    icon: faListAlt,
    route: '/app/meetings/lookups/meeting-type',
  },
  {
    title: 'Meeting Status',
    description: 'Manage the different statuses for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-status',
  },
  {
    // Meeting Requests
    title: 'Meeting Requests',
    description: 'Manage the different requests for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-request',
  },
  {
    // Meeting Setup
    title: 'Meeting Setups',
    description: 'Manage the different setups for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-setup',
  },
  {
    // Meeting Attendance Status
    title: 'Meeting Attendance Status',
    description: 'Manage the different attendance statuses for meetings in the system.',
    icon: faTasks,
    route: '/app/meetings/lookups/meeting-attendance-status',
  },
];

const MeetingLookups = () => {
  const navigate = useNavigate();

  const handleView = (route) => {
    navigate(route);
  };

  return (
    <div className="container mt-4">
      <h2>Meetings Lookups</h2>
      <Row>
        {Lookups_Meetings.map((lookup, index) => (
          <Col key={index} sm={12} md={6} lg={4} className="mb-4">
            <Card className="shadow h-100" onClick={() => handleView(lookup.route)} style={{ cursor: 'pointer' }}>
              <Card.Body className="d-flex flex-column align-items-center text-center">
                <FontAwesomeIcon icon={lookup.icon} size="3x" className="mb-3" />
                <Card.Title>{lookup.title}</Card.Title>
                <Card.Text>{lookup.description}</Card.Text>
                <Button variant="primary" onClick={() => handleView(lookup.route)}>View</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MeetingLookups;
