import { getApiMeetings } from '../../APIServiceSetup';

const api_meetings = await getApiMeetings();
const CACHE_KEY = 'locationVenueSetupCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper functions for cache management
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Get a list of location venue setups
export const getLocationVenueSetupList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_meetings.get('/api/LocationVenueSetup/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get a specific location venue setup by ID
export const getLocationVenueSetupById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get(`/api/LocationVenueSetup/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Save a new location venue setup
export const saveLocationVenueSetup = async (venueSetupData) => {
    try {
        const response = await api_meetings.post('/api/LocationVenueSetup/Save', venueSetupData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update an existing location venue setup
export const updateLocationVenueSetup = async (venueSetupData) => {
    try {
        const response = await api_meetings.post('/api/LocationVenueSetup/Update', venueSetupData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Delete a location venue setup
export const deleteLocationVenueSetup = async (id) => {
    try {
        const response = await api_meetings.delete(`/api/LocationVenueSetup/Delete/${id}`);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
