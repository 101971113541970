import { getApiLocations } from '../../APIServiceSetup';

const api_locations = await getApiLocations();

// Cache Helper Functions
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

const invalidateCache = (cacheKey) => {
    localStorage.removeItem(cacheKey);
};

// API Functions with Caching

// Address List
const ADDRESS_LIST_CACHE = 'addressListCache';
export const AddressList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(ADDRESS_LIST_CACHE)) {
        return getCache(ADDRESS_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/Address/List');
        setCache(ADDRESS_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Address By ID
export const AddressById = async (locationID, forceUpdate = false) => {
    const cacheKey = `addressByIdCache_${locationID}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/Address/${locationID}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Addresses By Location
export const GetAddressesByLocation = async (locationId, forceUpdate = false) => {
    const cacheKey = `addressesByLocationCache_${locationId}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/Address/ByLocation/${locationId}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Insert Address
export const InsertAddress = async (address) => {
    try {
        const response = await api_locations.post('/api/Address/Insert', address);
        invalidateCache(ADDRESS_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Save Address
export const SaveAddress = async (address) => {
    try {
        const response = await api_locations.post('/api/Address/Save', address);
        invalidateCache(ADDRESS_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update Address
export const UpdateAddress = async (address) => {
    try {
        const response = await api_locations.post('/api/Address/Update', address);
        invalidateCache(ADDRESS_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get All Addresses
export const GetAllAddresses = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(ADDRESS_LIST_CACHE)) {
        return getCache(ADDRESS_LIST_CACHE);
    }
    try {
        const response = await api_locations.get('/api/Address');
        setCache(ADDRESS_LIST_CACHE, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get Addresses By City
export const GetAddressesByCity = async (city, forceUpdate = false) => {
    const cacheKey = `addressesByCityCache_${city}`;
    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }
    try {
        const response = await api_locations.get(`/api/Address/ByCity/${city}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Change Address Suburb
export const ChangeAddressSuburb = async (locationID, newSuburb) => {
    try {
        const response = await api_locations.post(`/api/Address/ChangeSuburb/${locationID}/${newSuburb}`);
        invalidateCache(ADDRESS_LIST_CACHE);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
