import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Modal, Form, Alert } from 'react-bootstrap';

const StepSix = ({
    meetingRequests,
    meetingDetails,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
}) => {
    const stepRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState("");
    const [requestDetails, setRequestDetails] = useState("");
    const [requestList, setRequestList] = useState(meetingDetails.meetingRequests || []);

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleAddRequest = () => {
        if (selectedRequest && requestDetails) {
            const request = meetingRequests.find((req) => req.requestID === selectedRequest);
            const newRequest = {
                requestID: selectedRequest,
                name: request?.name,
                details: requestDetails,
            };
            const updatedList = [...requestList, newRequest];
            setRequestList(updatedList);
            handleMeetingDetailChange("meetingRequests", updatedList);
            setShowModal(false);
            setSelectedRequest("");
            setRequestDetails("");
        }
    };

    const handleRemoveRequest = (requestID) => {
        const updatedList = requestList.filter((req) => req.requestID !== requestID);
        setRequestList(updatedList);
        handleMeetingDetailChange("meetingRequests", updatedList);
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="text-primary text-center">Request Needs with Details</h4>

                    {requestList.length === 0 && (
                        <Alert variant="info" className="text-center">
                            No requests have been added yet. Click <strong>"Add Request"</strong> to start adding request details for your meeting.
                        </Alert>
                    )}

                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <Button
                            variant="primary"
                            onClick={() => setShowModal(true)}
                            className="mb-3"
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faPlus} /> Add Request
                        </Button>
                    </div>

                    <Row className="mb-4">
                        {requestList.map((request, index) => (
                            <Col md={6} key={index} className="mb-3">
                                <Card className="shadow">
                                    <Card.Body>
                                        <Card.Title>{request.name}</Card.Title>
                                        <Card.Text>
                                            <strong>Details:</strong> {request.details}
                                        </Card.Text>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleRemoveRequest(request.requestID)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} size="xs" /> Remove
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Request</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Request</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedRequest}
                                        onChange={(e) => setSelectedRequest(e.target.value)}
                                    >
                                        <option value="">Choose a request</option>
                                        {meetingRequests.map((request) => (
                                            <option key={request.requestID} value={request.requestID}>
                                                {request.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Details</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        value={requestDetails}
                                        onChange={(e) => setRequestDetails(e.target.value)}
                                        placeholder="Enter details about the request"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleAddRequest} disabled={!selectedRequest || !requestDetails}>
                                Add Request
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="secondary" onClick={handlePreviousStep}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleNextStep}
                        >
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepSix;
