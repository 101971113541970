import { getApiMeetings } from '../../APIServiceSetup';

const api_meetings = await getApiMeetings();
const CACHE_KEY = 'locationVenueRequestCache';
const CACHE_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

// Helper functions for cache management
const isCacheValid = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    if (!cachedData) return false;
    const now = new Date().getTime();
    return now - cachedData.timestamp < CACHE_TIMEOUT;
};

const setCache = (cacheKey, data) => {
    const cachedData = {
        data,
        timestamp: new Date().getTime(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
};

const getCache = (cacheKey) => {
    const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    return cachedData ? cachedData.data : null;
};

// Get a list of location venue requests
export const getLocationVenueRequestList = async (forceUpdate = false) => {
    if (!forceUpdate && isCacheValid(CACHE_KEY)) {
        return getCache(CACHE_KEY);
    }

    try {
        const response = await api_meetings.get('/api/LocationVenueRequest/List');
        setCache(CACHE_KEY, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Get a specific location venue request by ID
export const getLocationVenueRequestById = async (id, forceUpdate = false) => {
    const cacheKey = `${CACHE_KEY}_${id}`;

    if (!forceUpdate && isCacheValid(cacheKey)) {
        return getCache(cacheKey);
    }

    try {
        const response = await api_meetings.get(`/api/LocationVenueRequest/Get/${id}`);
        setCache(cacheKey, response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Save a new location venue request
export const saveLocationVenueRequest = async (venueRequestData) => {
    try {
        const response = await api_meetings.post('/api/LocationVenueRequest/Save', venueRequestData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Update an existing location venue request
export const updateLocationVenueRequest = async (venueRequestData) => {
    try {
        const response = await api_meetings.post('/api/LocationVenueRequest/Update', venueRequestData);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Delete a location venue request
export const deleteLocationVenueRequest = async (id) => {
    try {
        const response = await api_meetings.delete(`/api/LocationVenueRequest/Delete/${id}`);
        localStorage.removeItem(CACHE_KEY); // Invalidate cache
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
};
