import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Modal, Form, Alert } from 'react-bootstrap';

const StepFive = ({
    meetingSetups,
    meetingDetails,
    handleMeetingDetailChange,
    handleNextStep,
    handlePreviousStep,
}) => {
    const stepRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedSetup, setSelectedSetup] = useState("");
    const [setupDetails, setSetupDetails] = useState("");
    const [setupList, setSetupList] = useState(meetingDetails.meetingSetups || []);

    useEffect(() => {
        if (stepRef.current) {
            stepRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const handleAddSetup = () => {
        if (selectedSetup && setupDetails) {
            const setup = meetingSetups.find((setup) => setup.setupID === selectedSetup);
            const newSetup = {
                setupID: selectedSetup,
                name: setup?.name,
                departmentID: setup?.departmentID,
                details: setupDetails,
            };
            const updatedList = [...setupList, newSetup];
            setSetupList(updatedList);
            handleMeetingDetailChange("meetingSetups", updatedList);
            setShowModal(false);
            setSelectedSetup("");
            setSetupDetails("");
        }
    };

    const handleRemoveSetup = (setupID) => {
        const updatedList = setupList.filter((setup) => setup.setupID !== setupID);
        setSetupList(updatedList);
        handleMeetingDetailChange("meetingSetups", updatedList);
    };

    return (
        <div className="chat-message" ref={stepRef}>
            <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="card shadow mb-4 mx-auto position-relative"
                style={{ maxWidth: '800px' }}
            >
                <div className="card-body">
                    <h4 className="text-primary text-center">Setup Needs with Details</h4>

                    {setupList.length === 0 && (
                        <Alert variant="info" className="text-center">
                            No setups have been added yet. Click <strong>"Add Setup"</strong> to start adding setup details for your meeting.
                        </Alert>
                    )}

                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center">
                        
                        </div>
                        <Button
                            variant="primary"
                            onClick={() => setShowModal(true)}
                            className="mb-3"
                            size='sm'>
                            <FontAwesomeIcon icon={faPlus} /> Add Setup
                        </Button>
                    </div>

                    <Row className="mb-4">
                        {setupList.map((setup, index) => (
                            <Col md={6} key={index} className="mb-3">
                                <Card className="shadow">
                                    <Card.Body>
                                        <Card.Title>{setup.name}</Card.Title>
                                        <Card.Text>
                                            <strong>Department:</strong> {setup.departmentID}
                                            <br />
                                            <strong>Details:</strong> {setup.details}
                                        </Card.Text>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleRemoveSetup(setup.setupID)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} size="xs" /> Remove
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    

                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Setup</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Setup</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={selectedSetup}
                                        onChange={(e) => setSelectedSetup(e.target.value)}
                                    >
                                        <option value="">Choose a setup</option>
                                        {meetingSetups.map((setup) => (
                                            <option key={setup.setupID} value={setup.setupID}>
                                                {setup.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Provide Details</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={6}
                                        value={setupDetails}
                                        onChange={(e) => setSetupDetails(e.target.value)}
                                        placeholder="Enter details about the setup"
                                    />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleAddSetup} disabled={!selectedSetup || !setupDetails}>
                                Add Setup
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className="d-flex justify-content-between mt-4">
                        <Button variant="secondary" onClick={handlePreviousStep}>
                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleNextStep}
                        >
                            Next <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default StepFive;
