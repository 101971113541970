import React from "react";
import { Button } from "react-bootstrap";
import Shimmer from "../../../../../../components/Custom/Loading/Shimmer";

const LocationDetails = ({
    locationInfo,
    isLoading,
    isUpdating,
    updateLocationInfo, 
    locationID
}) => {
    const [formData, setFormData] = React.useState(locationInfo);

    React.useEffect(() => {
        setFormData(locationInfo);
    }, [locationInfo]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateLocationInfo(formData);
    };

    if (isLoading) {
        return <Shimmer type="article" />;
    }

    return (
        <div className="pt-3">
            <div className="settings-form">
                <h4 className="text-primary">Location Details</h4>
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="locationID" value={locationID} />
                    <input
                        type="hidden"
                        name="createdDateTime"
                        value={formData.createdDateTime}
                    />
                    <input
                        type="hidden"
                        name="modifiedDateTime"
                        value={formData.modifiedDateTime}
                    />
                    <div className="row">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">Name</label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Location Name"
                                className="form-control"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">Color</label>
                            <br />
                            <input
                                type="color"
                                name="color"
                                className="form-control-color"
                                value={formData.color}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                            name="description"
                            placeholder="Description"
                            className="form-control"
                            value={formData.description}
                            onChange={handleChange}
                            rows="3"
                        ></textarea>
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Details</label>
                        <textarea
                            name="details"
                            placeholder="Additional Details"
                            className="form-control"
                            value={formData.details}
                            onChange={handleChange}
                            rows="3"
                        ></textarea>
                    </div>
                    <div className="form-check custom-checkbox mb-3">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isActive"
                            name="isActive"
                            checked={formData.isActive}
                            onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="isActive">
                            Is Active
                        </label>
                    </div>
                    <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isUpdating}
                    >
                        {isUpdating ? "Updating..." : "Update"}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default LocationDetails;
