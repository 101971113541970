import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

// API Service
import { InsertLocation } from "../../../../../../services/ccms/locationManagement/location/location_endpoints";

const LocationModal = ({ show, handleClose, fetchLocations }) => {
  const [location, setLocation] = useState({
    name: "",
    isActive: true,
    color: "#000000",
    description: "",
    details: "",
  });
  const [saving, setSaving] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setLocation({
      ...location,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    toast.info("Saving location...");
    try {
      const currentDateTime = new Date()
        .toISOString()
        .replace(/[-:.T]/g, "")
        .slice(0, 14); // Format yyyyMMddHHmmss

      const newLocation = {
        ...location,
        createdDateTime: currentDateTime,
        modifiedDateTime: currentDateTime,
      };

      const response = await InsertLocation(newLocation);
      if (response.status === "Success") {
        toast.success(response.message);
        handleClose();
        fetchLocations(); // Refresh the locations list
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error saving location:", error);
      toast.error("An error occurred while saving the location.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={location.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="isActive" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Is Active"
              name="isActive"
              checked={location.isActive}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="color" className="mt-3">
            <Form.Label>Color</Form.Label>
            <Form.Control
              type="color"
              name="color"
              value={location.color}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="description" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={location.description}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="details" className="mt-3">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="details"
              value={location.details}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} size="sm">
          <FontAwesomeIcon icon={faTimes} /> Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={saving}
          size="sm"
        >
          {saving ? "Saving..." : <><FontAwesomeIcon icon={faSave} /> Save</>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
