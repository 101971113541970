import React from "react";
import { Button } from "react-bootstrap";
import Shimmer from "../../../../../../components/Custom/Loading/Shimmer";

const currentDateTime = new Date()
        .toISOString()
        .replace(/[-:.T]/g, "")
        .slice(0, 14); // Format yyyyMMddHHmmss

const LocationAddress = ({ address, isLoading, isUpdating, updateAddress, locationID }) => {
    // Ensure address has default values even if null or undefined
    const initialAddress = address || {
        locationID: locationID,
        name: "",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        suburb: "",
        city: "",
        code: "",
        province: "",
        country: "",
        geoLocationA: "",
        geoLocationL: "",
        createdDateTime: currentDateTime,
        modifiedDateTime: currentDateTime,
    };

    const [formData, setFormData] = React.useState(initialAddress);

    React.useEffect(() => {
        setFormData(address || initialAddress);
    }, [address]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateAddress(formData);
    };

    if (isLoading) {
        return <Shimmer type="article" />;
    }

    return (
        <div className="pt-3">
            <div className="settings-form">
                <h4 className="text-primary">Location Address</h4>
                <form onSubmit={handleSubmit}>
                    <input type="hidden" name="locationID" value={formData.locationID} />
                    <input
                        type="hidden"
                        name="createdDateTime"
                        value={formData.createdDateTime}
                    />
                    <input
                        type="hidden"
                        name="modifiedDateTime"
                        value={formData.modifiedDateTime}
                    />
                    <div className="form-group mb-3">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Address Name"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Address Line 1</label>
                        <input
                            type="text"
                            name="addressLine1"
                            placeholder="Address Line 1"
                            className="form-control"
                            value={formData.addressLine1}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Address Line 2</label>
                        <input
                            type="text"
                            name="addressLine2"
                            placeholder="Address Line 2"
                            className="form-control"
                            value={formData.addressLine2}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="form-label">Address Line 3</label>
                        <input
                            type="text"
                            name="addressLine3"
                            placeholder="Address Line 3"
                            className="form-control"
                            value={formData.addressLine3}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Suburb</label>
                            <input
                                type="text"
                                name="suburb"
                                placeholder="Suburb"
                                className="form-control"
                                value={formData.suburb}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label className="form-label">City</label>
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                className="form-control"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label className="form-label">Code</label>
                            <input
                                type="text"
                                name="code"
                                placeholder="Postal Code"
                                className="form-control"
                                value={formData.code}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">Province</label>
                            <input
                                type="text"
                                name="province"
                                placeholder="Province"
                                className="form-control"
                                value={formData.province}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">Country</label>
                            <input
                                type="text"
                                name="country"
                                placeholder="Country"
                                className="form-control"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">GeoLocation Latitude</label>
                            <input
                                type="text"
                                name="geoLocationA"
                                placeholder="Latitude"
                                className="form-control"
                                value={formData.geoLocationA}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label">GeoLocation Longitude</label>
                            <input
                                type="text"
                                name="geoLocationL"
                                placeholder="Longitude"
                                className="form-control"
                                value={formData.geoLocationL}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <Button
                        className="btn btn-primary"
                        type="submit"
                        disabled={isUpdating}
                    >
                        {isUpdating ? "Updating..." : "Update"}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default LocationAddress;
